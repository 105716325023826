import { useEffect } from "react";
import { Outlet, useLocation } from "react-router";
import { usePostHog } from "posthog-js/react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

export const PostHogPageviewTracker = () => {
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("$pageview");
  }, [location, posthog]);

  return <Outlet />;
};

export const PostHogIdentify = () => {
  const posthog = usePostHog();
  const session = useSessionContext();

  if (!session.loading && session.doesSessionExist) {
    useEffect(() => {
      if (posthog) {
        if (session.userId != posthog.get_distinct_id()) {
          posthog.identify(session.userId);
        }
      }
    }, [posthog, session.userId]);
  }

  return null;
};
