import { TallyGroupRes } from "@/api/tally";
import { useRouteLoaderData } from "react-router";
import { getTallyScores, getTeamsMap } from "../trackers/utils";
import { TrackerStatsComp } from "../trackers/stats";

export function TallyStats() {
  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = loaderData.tallies.map((tally) => getTallyScores(tally));

  return <TrackerStatsComp teamsMap={teamsMap} scores={scores} />;
}
