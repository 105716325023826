import { UserRes, getFriendRequests, getFriends } from "@/api/user";
import { AnimatedTick } from "@/components/ui/animated_tick";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { Button, CircleButton } from "@/components/ui/button";
import { NewCard, NewCardButton } from "@/components/ui/card";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import FormError from "@/components/ui/form_error";
import { Input } from "@/components/ui/input";
import { NavBar } from "@/components/ui/nav_bar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { MyText } from "@/components/ui/text";
import { UserInfo } from "@/components/ui/user_info";
import { share } from "@/share";
import {
  faCheck,
  faCircleInfo,
  faShareFromSquare,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  FetcherWithComponents,
  Link,
  useFetcher,
  useLoaderData,
  useRevalidator,
} from "react-router";

const addFriend = (fetcher: FetcherWithComponents<any>, email: string) => {
  fetcher.submit(
    { email: email || "" },
    {
      method: "POST",
      action: "/friends/add",
      encType: "application/json",
    },
  );
};

const AddInviteDialog = (props: { f?: UserRes }) => {
  const fetcher = useFetcher();
  const revalidate = useRevalidator();
  const isInvite = Boolean(props.f);
  const isSuccess = fetcher.data?.ok;

  type Inputs = {
    email: string;
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    if (isInvite) {
      fetcher.submit(
        { id: props.f?.id || 0, email: data.email || "" },
        {
          method: "POST",
          action: "/friends/fulfil",
          encType: "application/json",
        },
      );
    } else addFriend(fetcher, data.email);
  };

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data?.error) {
      setError("email", { type: "server", message: fetcher.data.error });
    }
  }, [fetcher]);

  const reset = (open: boolean) => {
    if (!open) {
      setValue("email", "");
      clearErrors();
      // fetcher.submit({ action: "/fetcher/reset" });
      fetcher.data = null;
      revalidate.revalidate();
    }
  };

  return (
    <Drawer onOpenChange={reset}>
      <DrawerTrigger asChild>
        <Button
          variant={isInvite ? "outline" : "default"}
          size={isInvite ? "xs" : "sm"}
        >
          {isInvite ? "Invite" : "Add"}
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        {!isSuccess ? (
          <>
            <DrawerHeader>
              <DrawerTitle>{isInvite ? "Invite" : "Add"}</DrawerTitle>
              <DrawerDescription>
                {isInvite
                  ? `Transfer the account ${props.f?.name} to a friend. This will allow them to log into the account with the provided email.`
                  : "Have a friend with an account already? Add them by their email here."}
              </DrawerDescription>
            </DrawerHeader>
            <fetcher.Form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-8 flex flex-col gap-1"
            >
              <Input
                placeholder="Enter an email..."
                type="email"
                {...register("email", {
                  required: "This field is required.",
                })}
              />
              <FormError error={errors.email} />
              <DrawerFooter>
                <CircleButton icon={faCheck} />
              </DrawerFooter>
            </fetcher.Form>
          </>
        ) : (
          <>
            <DrawerHeader>
              <DrawerTitle>Success</DrawerTitle>
              <DrawerDescription>
                {isInvite
                  ? `They can now log into ${props.f?.name} with their email. Share the link!`
                  : "Friend request sent. They will now be able to see games you add them to!"}
              </DrawerDescription>
            </DrawerHeader>
            <AnimatedTick className="w-40" />
            <DrawerFooter>
              {isInvite && Boolean(navigator.share) && (
                <CircleButton
                  icon={faShareFromSquare}
                  onClick={() =>
                    share(
                      `You've been invited to Pocket Tally! Sign in with your email: ${getValues(
                        "email",
                      )}`,
                    )
                  }
                />
              )}
              <DrawerClose asChild>
                <CircleButton icon={faXmark} />
              </DrawerClose>
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};

type friendsLoaderT = {
  requests: UserRes[];
  friends: UserRes[];
};
export async function friendsLoader(): Promise<friendsLoaderT> {
  const requests = await getFriendRequests();
  const friends = await getFriends();
  return { requests: requests, friends: friends };
}

export default function FriendsList() {
  const fetcher = useFetcher();
  const loaderData = useLoaderData() as friendsLoaderT;
  const requests = loaderData.requests;
  const friends = loaderData.friends.filter((f) => f.email);
  const localFriends = loaderData.friends.filter((f) => !f.email);

  return (
    <>
      <NavBar logo home />
      <BackWhite />
      <BackBlack>
        {requests.length != 0 && (
          <div>
            <MyText>Friend requests</MyText>
            <NewCard>
              <div className="flex flex-col gap-1">
                {requests.map((f) => (
                  <div key={f.id} className="flex items-center justify-between">
                    <UserInfo user={f} showEmail />
                    <Button
                      variant="outline"
                      size="xs"
                      onClick={() => addFriend(fetcher, f.email || "")}
                    >
                      Accept
                    </Button>
                  </div>
                ))}
              </div>
            </NewCard>
          </div>
        )}

        <div>
          <MyText>Friends</MyText>
          <NewCard>
            <div className="flex flex-col gap-1">
              {friends.length == 0 && (
                <MyText size="sm" alt>
                  No one here yet!
                </MyText>
              )}
              {friends.map((f) => (
                <div key={f.id} className="flex items-center justify-between">
                  <UserInfo user={f} showEmail />
                </div>
              ))}
            </div>
            <NewCardButton>
              <AddInviteDialog />
            </NewCardButton>
          </NewCard>
        </div>

        <div>
          <MyText>
            Local Accounts
            <Popover>
              <PopoverTrigger>
                <FontAwesomeIcon icon={faCircleInfo} className="ml-2" />
              </PopoverTrigger>
              <PopoverContent className="w-48 text-sm">
                These are self-created accounts. You can transfer them to a
                friend by inviting them.
              </PopoverContent>
            </Popover>
          </MyText>
          <NewCard>
            <div className="flex flex-col gap-1">
              {localFriends.length == 0 && (
                <MyText size="sm" alt>
                  No one here yet!
                </MyText>
              )}
              {localFriends.map((f) => (
                <div key={f.id} className="flex items-center justify-between">
                  <UserInfo user={f} showEmail />
                  <AddInviteDialog f={f} />
                </div>
              ))}
            </div>
            <NewCardButton>
              <Button size="sm" asChild>
                <Link to="/friends/create" viewTransition>
                  Create
                </Link>
              </Button>
            </NewCardButton>
          </NewCard>
        </div>
      </BackBlack>
    </>
  );
}
