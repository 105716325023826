import { faAward } from "@fortawesome/free-solid-svg-icons";
import { AvatarTeamXl } from "./avatar";
import { MyText } from "./text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserRes } from "@/api/user";
import { cn } from "@/lib/utils";

interface TeamCelebrateProps extends React.HTMLAttributes<HTMLDivElement> {
  team: UserRes[];
  score: number;
  animate?: boolean;
}

export const TeamCelebrate = ({
  team,
  score,
  animate,
  className,
}: TeamCelebrateProps) => (
  <div
    className={cn(
      "flex justify-center",
      animate && "animate-in zoom-in duration-1000",
      className,
    )}
  >
    <div
      className={cn(
        "relative bg-blue-500 rounded-full flex flex-col gap-6 items-center justify-center",
        team.length == 1 ? "w-40 h-40" : "w-44 h-44",
      )}
    >
      <FontAwesomeIcon
        icon={faAward}
        className="text-gold text-5xl absolute bottom-0 right-0"
      />
      <AvatarTeamXl users={team} />
      <div className="flex items-center justify-center">
        <MyText>{score}</MyText>
      </div>
    </div>
  </div>
);
