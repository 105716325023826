import { ScorecardGroupRes } from "@/api/scorecard";
import { useRouteLoaderData } from "react-router";
import { TrackerLeaderboardComp } from "../trackers/leaderboard";
import { getSummedScores, getTeamsMap } from "../trackers/utils";

export function ScorecardLeaderboard() {
  const loaderData = useRouteLoaderData("gameroot") as ScorecardGroupRes;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = loaderData.scorecards.map((scorecard) =>
    getSummedScores(scorecard.scores),
  );

  return (
    <TrackerLeaderboardComp
      permission_level={loaderData.group.permission_level}
      teamsMap={teamsMap}
      scores={scores}
    />
  );
}
