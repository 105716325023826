import { ScorecardGroupRes } from "@/api/scorecard";
import { useRouteLoaderData } from "react-router";
import { TrackerHistoryComp } from "../trackers/history";
import { getSummedScores, getTeamsMap } from "../trackers/utils";

export function ScorecardHistory() {
  const loaderData = useRouteLoaderData("gameroot") as ScorecardGroupRes;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = loaderData.scorecards.map((scorecard) =>
    getSummedScores(scorecard.scores),
  );
  return (
    <TrackerHistoryComp
      trackers={loaderData.scorecards}
      groupData={loaderData.group}
      teamsMap={teamsMap}
      scores={scores}
      allowRead
    />
  );
}
