import { GameType } from "@/api/game";
import { GameStore } from "@/storage";
import { useParams } from "react-router";
import { TallyCelebrate } from "../tally/celebrate";
import { ScorecardCelebrate } from "../scorecard/celebrate";
import { NavBar } from "@/components/ui/nav_bar";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { ActionIsland } from "@/components/ui/action_island";
import { CircleButton } from "@/components/ui/button";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { MyText } from "@/components/ui/text";
import { LeaderBoard, LeaderBoardRank } from "@/components/ui/leaderboard";
import { ConfettiCanvas } from "@/components/ui/confetti";
import { TeamCelebrate } from "@/components/ui/team_celebrate";
import { Scores, TeamMap } from "./utils";

export function TrackerCelebrate() {
  const params = useParams();
  const gameID = params.gameID || "";

  let type = new GameStore().get(gameID)?.type;

  switch (type) {
    case GameType.Tally:
      return <TallyCelebrate />;
    case GameType.Scorecard:
      return <ScorecardCelebrate />;
  }
}

type TrackerCelebrateCompProps = {
  teamsMap: TeamMap;
  scores: Scores;
};
export const TrackerCelebrateComp = ({
  teamsMap,
  scores,
}: TrackerCelebrateCompProps) => {
  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";

  const gameName = new GameStore().get(gameID)?.name;
  const sortedScores = scores.sort((a, b) => (a.score < b.score ? 1 : -1));

  return (
    <>
      <ActionIsland>
        {/*
        {Boolean(navigator.share) && <CircleButton icon={faShare} />}
        */}
        <CircleButton
          icon={faArrowRight}
          link={`/games/${gameID}/groups/${groupID}/trackers`}
        />
      </ActionIsland>

      <NavBar logo home />

      <BackWhite className="relative">
        <ConfettiCanvas />
        <TeamCelebrate
          team={teamsMap[sortedScores[0].team_id].users}
          score={sortedScores[0].score}
          animate
        />
        <MyText>{gameName}</MyText>
      </BackWhite>
      <BackBlack>
        <MyText>Scores</MyText>
        <LeaderBoard>
          {sortedScores.map((s, i) => (
            <LeaderBoardRank
              key={i}
              rank={i + 1}
              team={teamsMap[s.team_id]}
              score={s.score}
            />
          ))}
        </LeaderBoard>
      </BackBlack>
    </>
  );
};
