import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { NavBar } from "@/components/ui/nav_bar";
import { MyText } from "@/components/ui/text";
import clsx from "clsx";
import { ReactNode } from "react";
import { useNavigate } from "react-router";

function Para({ children, bold }: { children: ReactNode; bold?: boolean }) {
  return (
    <MyText size="xs" alt className={clsx(bold && "font-semibold")}>
      {children}
    </MyText>
  );
}
function List({ children }: { children: ReactNode }) {
  return (
    <ul className="list-disc list-inside ps-2 text-xs mt-2 font-main">
      {children}
    </ul>
  );
}

export default function Terms() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar backFunc={() => navigate(-1)} logo />
      <BackWhite>
        <MyText>Terms of Service</MyText>
      </BackWhite>
      <BackBlack>
        <MyText size="text" alt>
          Introduction
        </MyText>
        <Para>
          Welcome to Pocket Tally! These Terms of Service ("Terms") govern your
          use of our apps, services, and any related content (collectively, the
          "Service"). By accessing or using Pocket Tally, you agree to be bound
          by these Terms. If you do not agree with these Terms, you may not use
          the Service.
        </Para>
        <Para>
          We may update or modify these Terms from time to time. Any changes
          will be posted on this page, and we encourage you to review the Terms
          regularly. Your continued use of Pocket Tally after any changes
          signifies your acceptance of the updated Terms.
        </Para>
        <MyText size="text" alt>
          Last Updated
        </MyText>
        <Para>24 September 2024</Para>

        <MyText size="text" alt>
          Definitions
        </MyText>
        <Para>
          For the purposes of these Terms, the following definitions apply:
        </Para>
        <List>
          <li>
            "App" or "Service": Refers to Pocket Tally, including all
            functionalities, features, and content available to users.
          </li>
          <li>
            "User": Any individual or entity that accesses or uses the Service,
            whether registered or unregistered.
          </li>
          <li>
            "Content": Refers to any text, images, data, information, or other
            materials available through or provided via the Service, including
            any content generated by users.
          </li>
          <li>
            "Account": Refers to the profile created by a user when registering
            for the Service, containing personal information and settings.
          </li>
        </List>

        <MyText size="text" alt>
          Eligibility
        </MyText>
        <Para>
          In order to use Pocket Tally, you must meet the following eligibility
          criteria:
        </Para>
        <List>
          <li>
            Minimum Age: You must be at least 13 years old to use the Service.
            If you are under the age of 18, you may only use the Service under
            the supervision of a parent or legal guardian who agrees to be bound
            by these Terms.
          </li>
          <li>
            Compliance with Laws: You agree to comply with all applicable local,
            national, and international laws and regulations when using the
            Service.
          </li>
        </List>
        <MyText size="text" alt>
          User Responsibilities
        </MyText>
        <Para>
          By using Pocket Tally, you agree to the following responsibilities:
        </Para>
        <List>
          <li>
            Proper Use: You agree to use Pocket Tally only for lawful purposes
            and in accordance with these Terms. You are prohibited from using
            the Service in any way that could damage, disable, overburden, or
            impair the Service, or interfere with any other party's use of the
            Service.
          </li>
          <li>
            Prohibited Activities: You agree not to engage in the following
            activities:
            <List>
              <li>
                Using the Service for any illegal or unauthorized purpose.
              </li>
              <li>
                Accessing or using Pocket Tally to distribute harmful or
                malicious content, including viruses, spam, or malware.
              </li>
              <li>
                Attempting to gain unauthorized access to the Service, other
                accounts, or computer systems by any means.
              </li>
            </List>
          </li>
          <li>
            Content Submission: You are solely responsible for the content you
            provide. You agree that your submitted content will not:
            <li>
              Infringe upon any third-party rights, including intellectual
              property or privacy rights.
            </li>
            <li>
              Contain unlawful, defamatory, abusive, or obscene materials.
            </li>
            <li>Violate any applicable laws or regulations.</li>
          </li>
          <li>
            Account Security: You are responsible for maintaining the
            confidentiality of your account, and for any activity under your
            account. If you suspect unauthorized use of your account, you must
            notify us immediately.
          </li>
        </List>

        <MyText size="text" alt>
          Intellectual Property
        </MyText>
        <Para>
          The intellectual property rights related to Pocket Tally, including
          the web app, content, and any associated materials, are protected by
          applicable intellectual property laws. By using the Service, you
          acknowledge and agree to the following:
        </Para>
        <List>
          <li>
            Ownership: Pocket Tally, along with all of its content, features,
            and functionality (including but not limited to text, graphics,
            logos, icons, images, and software), is the exclusive property of
            Pocket Tally or its licensors and is protected by copyright,
            trademark, and other intellectual property laws.
          </li>
          <li>
            Limited License: We grant you a limited, non-exclusive,
            non-transferable, and revocable license to access and use the
            Service for personal, non-commercial purposes, subject to these
            Terms. You may not use any of the Pocket Tally materials for any
            purpose other than as expressly permitted in these Terms.
          </li>
          <li>
            User Content: By sharing your content through Pocket Tally, you’re
            helping us improve and enhance the experience for everyone! When you
            submit content, you’re giving us permission (a worldwide,
            royalty-free, non-exclusive license) to use, modify, display, and
            distribute it, but only in ways that support the growth and
            improvement of the Service. Rest assured, we respect your ownership
            of the content and will always use it responsibly. You also confirm
            that you have the rights to share this content and that it doesn’t
            violate the rights of any third party.
          </li>
          <li>
            Restrictions: You may not modify, reproduce, distribute, create
            derivative works, publicly display, or exploit any content from
            Pocket Tally without our prior written consent, except as permitted
            by these Terms or applicable law.
          </li>
        </List>

        <MyText size="text" alt>
          Privacy Policy
        </MyText>
        <Para>
          Your privacy is important to us at Pocket Tally. Our Privacy Policy
          explains how we collect, use, and protect your personal data when you
          use the Service. By using Pocket Tally, you agree to the terms
          outlined in our Privacy Policy.
        </Para>

        <MyText size="text" alt>
          Termination
        </MyText>
        <Para>
          Pocket Tally reserves the right to suspend or terminate your access to
          the Service at any time, for any reason, without prior notice or
          liability.
        </Para>
        <Para>
          Upon termination, your right to use Pocket Tally will immediately
          cease. Any data associated with your account may be deleted or made
          inaccessible at our discretion. Pocket Tally is not liable for any
          losses or damages that may result from termination, including loss of
          data.
        </Para>

        <MyText size="text" alt>
          Disclaimers and Limitation of Liability
        </MyText>
        <Para>
          The use of Pocket Tally is at your own risk. The Service is provided
          on an "as is" and "as available" basis without warranties of any kind,
          either express or implied. To the fullest extent permitted by law,
          Pocket Tally disclaims all warranties.
        </Para>
        <List>
          <li>
            No Warranty: Pocket Tally does not warrant that the Service will be
            uninterrupted, secure, or error-free, or that any defects will be
            corrected. We do not guarantee the accuracy or completeness of any
            content provided through the Service.
          </li>
          <li>
            Limitation of Liability: To the fullest extent permitted by law,
            Pocket Tally, its affiliates, and its licensors will not be liable
            for any indirect, incidental, special, consequential, or punitive
            damages, including but not limited to loss of profits, data, or
            other intangible losses resulting from:
            <List>
              <li>Your use or inability to use the Service.</li>
              <li>Unauthorized access to or alteration of your data.</li>
              <li>Any errors, bugs, or interruptions in the Service.</li>
            </List>
          </li>
        </List>
        <Para>
          In no event shall Pocket Tally’s total liability exceed the amount you
          have paid to use the Service, if any.
        </Para>

        <MyText size="text" alt>
          Indemnification
        </MyText>
        <Para>
          You agree to indemnify, defend, and hold harmless Pocket Tally, its
          affiliates, partners, employees, and licensors from and against any
          and all claims, liabilities, damages, losses, and expenses, including
          legal fees, arising out of or related to:
        </Para>
        <List>
          <li>Your use of the Service.</li>
          <li>Your violation of these Terms or any applicable laws.</li>
          <li>
            Any content you submit or actions you take using the Service that
            infringe the rights of others.
          </li>
        </List>
        <Para>
          Pocket Tally reserves the right, at its own expense, to assume the
          exclusive defense and control of any matter subject to indemnification
          by you, and you agree to cooperate with our defense in such cases.
        </Para>

        <MyText size="text" alt>
          Governing Law
        </MyText>
        <Para>
          The laws of the Country, excluding its conflicts of law rules, shall
          govern this Terms and Your use of the Service. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
        </Para>

        <MyText size="text" alt>
          Disputes Resolution
        </MyText>
        <Para>
          In the event of any disputes related to these Terms or the use of
          Pocket Tally, you agree to attempt to resolve the matter through
          informal negotiations first.
        </Para>

        <MyText size="text" alt>
          Contact Us
        </MyText>
        <Para>
          If you have any questions about the Terms of Service, you can contact
          us:{" "}
          <a className="underline" href="mailto:contact@mail.pockettally.app">
            contact@mail.pockettally.app
          </a>
        </Para>
        <br />
      </BackBlack>
    </>
  );
}
