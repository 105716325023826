import { ScorecardGroupRes } from "@/api/scorecard";
import { useParams, useRouteLoaderData } from "react-router";
import { TrackerCelebrateComp } from "../trackers/celebrate";
import { getSummedScores, getTeamsMap } from "../trackers/utils";

export function ScorecardCelebrate() {
  const params = useParams();
  const trackerID = params.trackerID || "";

  const loaderData = useRouteLoaderData("gameroot") as ScorecardGroupRes;
  const loaderScorecard = loaderData.scorecards.find((s) => s.id == trackerID);
  if (!loaderScorecard) throw Error("Scorecard not found.");

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = getSummedScores(loaderScorecard.scores);

  return <TrackerCelebrateComp teamsMap={teamsMap} scores={scores} />;
}
