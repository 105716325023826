import { cn } from "@/lib/utils";
import React from "react";

export const BackWhite = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "p-4 sm:px-8",
        "bg-white text-black flex flex-col gap-4",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});

export const BackBlack = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        "relative z-0",
        "px-4 pt-2 pb-32 sm:px-8",
        "bg-black text-white min-h-full grow flex flex-col gap-8",
        "shadow-backblack",
        "[view-transition-name:backblack]",
        className,
      )}
      {...props}
    >
      {/* Stick top corners - fiddly - check the shadows. The sticky top-x must match the nav bar height. */}
      <div className="pointer-events-none absolute top-0 left-0 right-0 bottom-0">
        <div className="bg-transparent rounded-t-3xl shadow-up h-6 sticky top-24 z-30 overflow-hidden">
          <div className="bg-black absolute top-0 left-0 right-0 h-2" />
        </div>
      </div>
      {children}
    </div>
  );
};
