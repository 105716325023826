import { UserRes } from "@/api/user";
import { Avatar, AvatarImage } from "./avatar";

export const UserInfo = ({
  user,
  showEmail,
}: {
  user: UserRes;
  showEmail?: boolean;
}) => {
  return (
    <div className="flex items-center gap-2">
      <Avatar>
        <AvatarImage src={user.avatarUrl} />
      </Avatar>
      <div className="flex flex-col">
        <p className="font-main leading-none">{user.name}</p>
        {showEmail && user.email && (
          <p className="font-main text-xs text-grey">{user.email}</p>
        )}
      </div>
    </div>
  );
};
