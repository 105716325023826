import { MyText } from "./text";

type Props = {
  error: any;
};
export default function FormError({ error }: Props) {
  return (
    <>
      {error && (
        <MyText size="text" alt className="text-left mb-2 text-red">
          {error.message}
        </MyText>
      )}
    </>
  );
}
