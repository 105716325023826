import { TeamRes } from "@/api/game";
import { TallyRes, TallyScoreRes } from "@/api/tally";

export const getTeamsMap = (teams: TeamRes[]) => {
  return Object.assign({}, ...teams.map((t) => ({ [t.id]: t })));
};

export type TeamMap = { [id: string]: TeamRes };
export type Scores = TallyScoreRes[];

export const getTallyScores = (tally: TallyRes): Scores => {
  return tally.scores.sort((a, b) => (a.team_id > b.team_id ? 1 : -1));
};

export const getSummedScores = (scores: Scores): Scores => {
  let scorecardSums: { [id: string]: number } = {};
  scores.forEach((s) => {
    scorecardSums[s.team_id] ??= 0;
    scorecardSums[s.team_id] += s.score;
  });

  return Array.from(new Set(scores.map((s) => s.team_id)))
    .map((team_id) => ({
      team_id: team_id,
      score: scorecardSums[team_id] || 0,
    }))
    .sort((a, b) => (a.team_id > b.team_id ? 1 : -1));
};

export const getWinningTeams = (scores: Scores[]) => {
  const winners: string[][] = [];

  scores.forEach((game_scores) => {
    let maxScore = -Infinity;
    const game_winners: string[] = [];

    game_scores.forEach((team_score) => {
      const score = team_score.score;
      if (score > maxScore) {
        maxScore = score;
        game_winners.length = 0;
        game_winners.push(team_score.team_id);
      } else if (score === maxScore) {
        // For ties there can be multiple winners
        game_winners.push(team_score.team_id);
      }
    });
    winners.push(game_winners);
  });
  return winners;
};
export const getTeamWins = (scores: Scores[]) => {
  const wins: { [key: string]: number } = {};

  const winners = getWinningTeams(scores);
  winners.flat().forEach((winner) => {
    wins[winner] ??= 0;
    wins[winner]++;
  });

  return scores[0].map((score) => ({
    team_id: score.team_id,
    stat: wins[score.team_id] || 0,
  }));
};

export const getTeamTotals = (scores: Scores[]) => {
  const totals: { [key: string]: number } = {};

  scores.forEach((game_scores) => {
    game_scores.forEach((team_score) => {
      totals[team_score.team_id] ??= 0;
      totals[team_score.team_id] += team_score.score;
    });
  });

  return Object.keys(totals).map((team_id) => ({
    team_id: team_id,
    stat: totals[team_id],
  }));
};
