import { ReactNode, useRef, useState } from "react";
import { GameTypeComp, GameStateT } from "./type";
import { useSubmit } from "react-router";
import { usePostHog } from "posthog-js/react";

export default function GameCreate() {
  const posthog = usePostHog();
  const submit = useSubmit();

  // State required to create the game
  let gameState = useRef<GameStateT>({ name: "", type: "" });

  // Wizard for navigation
  const [wizard, wizardSetter] = useState(["gameType"]);
  function nextStep(step: string) {
    // TODO; validate step is in steps

    if (step == "submit") {
      posthog?.capture("game_create");
      submit(gameState.current, {
        method: "POST",
        encType: "application/json",
      });
    } else wizardSetter([...wizard, step]);
  }
  // function prevStep() {
  //   let w = [...wizard];
  //   w.pop();
  //   wizardSetter(w);
  // }

  // Every page knows it's own forward route(s)
  // Backwards navigation handled by the wizard
  const steps: { [id: string]: ReactNode } = {
    gameType: <GameTypeComp nextStep={nextStep} gameState={gameState} />,
    // tournament setup
    // scorecard setup
  };

  return <>{steps[wizard[wizard.length - 1]]}</>;
}
