import { CircleButton } from "@/components/ui/button";
import { GameType } from "@/api/game";
import { GameStore } from "@/storage";
import { useParams } from "react-router";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { NavBar } from "@/components/ui/nav_bar";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { MyText } from "@/components/ui/text";
import {
  Scores,
  TeamMap,
  getSummedScores,
  getTeamWins,
  getWinningTeams,
} from "./utils";
import { TallyStats } from "../tally/stats";
import { ScorecardStats } from "../scorecard/stats";
import { StatBox } from "@/components/ui/stat";
import { NewCard } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { AvatarTeam } from "@/components/ui/avatar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Colors,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useRef, useState } from "react";
ChartJS.register(
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Colors,
  Legend,
);

export function TrackerStats() {
  const params = useParams();
  const gameID = params.gameID || "";

  let type = new GameStore().get(gameID)?.type;

  switch (type) {
    case GameType.Tally:
      return <TallyStats />;
    case GameType.Scorecard:
      return <ScorecardStats />;
  }
}

type TrackerStatsCompProps = {
  teamsMap: TeamMap;
  scores: Scores[];
};
export function TrackerStatsComp({ teamsMap, scores }: TrackerStatsCompProps) {
  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";

  const gameName = new GameStore().get(gameID)?.name;

  const totalPlays = scores.length;
  const totalTeams = scores[0].length;
  const totalScores = totalTeams * totalPlays;

  const wins = getTeamWins(scores).sort((a, b) =>
    a.team_id > b.team_id ? 1 : -1,
  );
  const summedScores = getSummedScores(scores.flat());
  const topScores = scores[0]
    .map((team_score) =>
      scores
        .flat()
        .filter((s) => s.team_id == team_score.team_id)
        .reduce((a, b) => (a.score > b.score ? a : b)),
    )
    .sort((a, b) => (a.team_id > b.team_id ? 1 : -1));

  const topWins = wins.reduce((a, b) => (a.stat > b.stat ? a : b));
  const topScore = topScores.reduce((a, b) => (a.score > b.score ? a : b));

  return (
    <>
      <NavBar backTo={`/games/${gameID}/groups/${groupID}/trackers`} logo home>
        <CircleButton
          icon={faTableList}
          link={`/games/${gameID}/groups/${groupID}/trackers/history`}
        />
      </NavBar>

      <BackWhite>
        <WinsChart teamsMap={teamsMap} scores={scores} />
        <MyText>{gameName}</MyText>
      </BackWhite>

      <BackBlack>
        <MyText>Stats</MyText>
        <div className="grid auto-rows-fr grid-cols-2 gap-y-10 justify-items-center">
          <StatBox
            title="Top Score"
            stat={topScore.score}
            team={teamsMap[topScore.team_id]}
          />
          <StatBox
            title="Top Win %"
            stat={Math.round((topWins.stat * 100) / totalPlays)}
            team={teamsMap[topWins.team_id]}
          />
          <StatBox title="Plays" stat={totalPlays} />
          <StatBox
            title="Avg. Score"
            stat={Math.round(
              summedScores.reduce((sum, a) => sum + a.score, 0) / totalScores,
            )}
          />
        </div>
        <NewCard>
          <Table className="[&_*]:border-black">
            <TableHeader>
              <TableRow>
                <TableHead></TableHead>
                <TableHead>Wins</TableHead>
                <TableHead>%</TableHead>
                <TableHead>Best</TableHead>
                <TableHead>Average</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Array.from({ length: totalTeams }, (_, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <AvatarTeam users={teamsMap[wins[i].team_id].users} />
                  </TableCell>
                  <TableCell>{wins[i].stat}</TableCell>
                  <TableCell>
                    {Math.round((wins[i].stat * 100) / totalPlays)}
                  </TableCell>
                  <TableCell>{topScores[i].score}</TableCell>
                  <TableCell>
                    {Math.round(summedScores[i].score / totalPlays)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </NewCard>
      </BackBlack>
    </>
  );
}

function WinsChart({
  scores,
  teamsMap,
}: {
  scores: Scores[];
  teamsMap: TeamMap;
}) {
  // Get the data
  const winningTeams = getWinningTeams(scores);
  let accumulatedWins: { [key: string]: number[] } = {};
  winningTeams.forEach((winners, i) => {
    winners.forEach((winner) => {
      accumulatedWins[winner] ??= Array(winningTeams.length).fill(0);
      accumulatedWins[winner][i]++;
    });
  });
  // Accumulate the wins over time
  for (const team in accumulatedWins) {
    const wins = accumulatedWins[team];
    for (let i = 1; i < wins.length; i++) {
      wins[i] += wins[i - 1];
    }
  }

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState<ChartJS | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      setChartInstance(chartRef.current);
    }
  }, [chartRef.current]);

  return (
    <div>
      <Line
        ref={chartRef}
        options={{
          responsive: true,
          plugins: {
            title: { display: true, text: "Wins" },
            legend: { display: false },
          },
        }}
        data={{
          labels: Array.from({ length: winningTeams.length }).map(() => ""),
          datasets: Object.keys(accumulatedWins).map((team_id) => ({
            label: team_id,
            data: accumulatedWins[team_id],
          })),
        }}
        redraw={true}
      />
      {chartInstance && (
        <HtmlLegend chart={chartInstance} teamsMap={teamsMap} />
      )}
    </div>
  );
}

interface HtmlLegendProps {
  chart: ChartJS;
  teamsMap: TeamMap;
}

const HtmlLegend: React.FC<HtmlLegendProps> = ({ chart, teamsMap }) => {
  // Sometimes it might not draw the legend...
  if (!chart.legend) return <></>;

  const labels = chart.options?.plugins?.legend?.labels;
  if (!labels?.generateLabels) return <></>;
  const items = labels.generateLabels(chart);

  // const handleLegendClick = (item: any) => {
  //   const { type } = chart.config;
  //   if (type === "pie" || type === "doughnut") {
  //     chart.toggleDataVisibility(item.index);
  //   } else {
  //     chart.setDatasetVisibility(
  //       item.datasetIndex,
  //       !chart.isDatasetVisible(item.datasetIndex),
  //     );
  //   }
  //   chart.update();
  // };

  return (
    <div className="flex gap-3">
      {items.map((item, index) => (
        <div
          key={index}
          className="rounded-full pl-4"
          style={{
            backgroundColor: item.strokeStyle?.toString(),
          }}
        >
          <AvatarTeam users={teamsMap[item.text].users} />
        </div>
      ))}
    </div>
  );
};
