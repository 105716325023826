import { TeamRes } from "@/api/game";
import { AvatarTeamLg } from "./avatar";
import { MyText } from "./text";
import { NewCard } from "./card";

export interface StatBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  stat: number;
  team?: TeamRes;
}
export const StatBox = ({ title, stat, team }: StatBoxProps) => (
  <NewCard className="flex flex-col gap-3 items-center w-32 h-32">
    <MyText size="text">{title}</MyText>
    <MyText size="xl" className="border-b border-orange-500">
      {stat}
    </MyText>
    {team && (
      <div className="pt-2">
        <AvatarTeamLg users={team.users} />
      </div>
    )}
  </NewCard>
);
