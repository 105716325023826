import { TallyGroupRes } from "@/api/tally";
import { useRouteLoaderData } from "react-router";
import { TrackerLeaderboardComp } from "../trackers/leaderboard";
import { getTallyScores, getTeamsMap } from "../trackers/utils";

export function TallyLeaderboard() {
  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = loaderData.tallies.map((tally) => getTallyScores(tally));

  return (
    <TrackerLeaderboardComp
      permission_level={loaderData.group.permission_level}
      teamsMap={teamsMap}
      scores={scores}
    />
  );
}
