import { useEffect, useRef } from "react";
import confetti from "canvas-confetti";

// Pagewide
export const useConfetti = () => {
  useEffect(() => {
    const fireConfetti = () => {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    };

    const timer = setTimeout(fireConfetti, 500);
    return () => clearTimeout(timer);
  }, []);
};

// Contained in div with relative
export const ConfettiCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context) {
        const instance = confetti.create(canvas, {
          resize: true,
        });
        function fire(particleRatio: number, opts: confetti.Options) {
          instance({
            ...{
              origin: { y: 1.2 },
            },
            ...opts,
            particleCount: Math.floor(200 * particleRatio),
          });
        }

        let timers = [];
        timers.push(
          setTimeout(() => {
            fire(0.25, {
              spread: 26,
              startVelocity: 55,
            });
            fire(0.2, {
              spread: 60,
            });
            fire(0.35, {
              spread: 100,
              decay: 0.91,
              scalar: 0.8,
            });
            fire(0.1, {
              spread: 120,
              startVelocity: 25,
              decay: 0.92,
              scalar: 1.2,
            });
            fire(0.1, {
              spread: 120,
              startVelocity: 45,
            });
          }, 500),
        );
        return () => timers.forEach((t) => clearTimeout(t));
      }
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute top-0 h-full left-0 w-full pointer-events-none z-30"
    />
  );
};

// Contained in div with relative
export const ConfettiDropCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context) {
        const instance = confetti.create(canvas, {
          resize: true,
        });

        const options = {
          particleCount: 100,
          spread: 360,
          startVelocity: 15,
          gravity: 0.8,
          origin: { y: -0.5 },
          disableForReducedMotion: true,
        };
        let timers = [];
        timers.push(
          setTimeout(() => {
            instance(options);
          }, 0),
        );
        return () => timers.forEach((t) => clearTimeout(t));
      }
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute top-0 h-full left-0 w-full pointer-events-none z-30"
    />
  );
};
