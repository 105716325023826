import { ProfileForm } from "./form";
import { NavBar } from "@/components/ui/nav_bar";

export default function ProfileCreate() {
  return (
    <>
      <NavBar logo />

      <ProfileForm action="/profile/create" btnTxt="Continue" />
    </>
  );
}
