import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { UserRes } from "@/api/user";
import { useLoaderData, useParams, useRouteLoaderData } from "react-router";
import { useWizard } from "@/components/ui/wizard";
import { ActionIsland } from "@/components/ui/action_island";
import { Button, CircleButton } from "@/components/ui/button";
import { faCheck, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { NavBar } from "@/components/ui/nav_bar";
import { MyText } from "@/components/ui/text";
import { GameStore } from "@/storage";
import { NewCard } from "@/components/ui/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormError from "@/components/ui/form_error";
import { UserInfo } from "@/components/ui/user_info";
import { useForm } from "react-hook-form";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Checkbox } from "@/components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Switch } from "@/components/ui/switch";

type Inputs = {
  selectedTeams: UserRes[][];
};
export default function PlayerSelect({
  teamsState,
}: {
  teamsState: [UserRes[][], Dispatch<SetStateAction<UserRes[][]>>];
}) {
  const { nextStep, submit } = useWizard();
  const params = useParams();
  const gameName = new GameStore().get(params.gameID || "")?.name;
  const userData = useRouteLoaderData("profilecheck") as UserRes;
  const friendData = useLoaderData() as UserRes[];
  const users = [userData, ...friendData];

  const [teams, teamsSetter] = teamsState;
  const [isTeams, setIsTeams] = useState(false);
  // When not using teams - display all players together
  const displayTeams = isTeams ? [...teams, []] : [teams.flat()];

  function onTeamsSwitch(checked: boolean) {
    // Spread players into seperate teams
    let spreadTeams = teams.flat().map((u) => [u]);
    teamsSetter(spreadTeams);
    setIsTeams(checked);
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ values: { selectedTeams: teams } });

  function onCheck(team: number, user: UserRes, checked: CheckedState) {
    let newTeams = [...teams];

    if (isTeams) {
      if (team == newTeams.length) newTeams.push([]);
      if (checked) {
        newTeams[team].push(user);
      } else {
        newTeams[team] = newTeams[team].filter((x) => x.id != user.id);
      }
    } else {
      if (checked) {
        newTeams.push([user]);
      } else {
        newTeams = newTeams.filter((x) => x[0].id != user.id);
      }
    }
    teamsSetter(newTeams);
  }
  const drawerContent = (team: number) => {
    const userChecked = (id: string) =>
      teams
        .flat()
        .map((x) => x.id)
        .includes(id);

    return (
      <DrawerContent>
        <div className="flex flex-col gap-2 max-h-96 overflow-y-scroll pt-4">
          {users.map((u) => (
            <Fragment key={u.id}>
              <label>
                <div className="flex justify-between items-center">
                  <UserInfo user={u} />
                  <Checkbox
                    onCheckedChange={(b) => onCheck(team, u, b)}
                    defaultChecked={userChecked(u.id)}
                    // Disabled if isTeams, checked and not in this team
                    disabled={
                      isTeams &&
                      userChecked(u.id) &&
                      (team == teams.length ||
                        !teams[team].map((x) => x.id).includes(u.id))
                    }
                  />
                </div>
              </label>
            </Fragment>
          ))}
        </div>
        <DrawerFooter>
          <CircleButton icon={faPlus} onClick={() => nextStep("addFriend")} />
          <DrawerClose asChild>
            <CircleButton icon={faCheck} />
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    );
  };

  return (
    <>
      <ActionIsland>
        <CircleButton icon={faPlay} form="theform" />
      </ActionIsland>

      <NavBar logo home />
      <BackWhite>
        <div className="flex justify-between">
          <MyText>{gameName}</MyText>
          <div className="flex items-center">
            <Switch checked={isTeams} onCheckedChange={onTeamsSwitch} />
            <MyText size="text" alt>
              Teams
            </MyText>
          </div>
        </div>
      </BackWhite>

      <BackBlack>
        <MyText>Players</MyText>

        {displayTeams.map((team, i) => (
          <NewCard key={i} className="relative min-h-16">
            <Drawer>
              <DrawerTrigger asChild>
                <Button
                  className="absolute top-3 right-3"
                  variant="iconAlt"
                  size="iconAlt"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </DrawerTrigger>
              {drawerContent(i)}
            </Drawer>

            <div className="flex flex-col gap-2">
              {team.map((u) => (
                <UserInfo key={u.id} user={u} />
              ))}
            </div>
          </NewCard>
        ))}

        <form id="theform" onSubmit={handleSubmit(() => submit())}>
          <input
            className="hidden"
            {...register("selectedTeams", {
              validate: () =>
                teams.flat().length != 0 || "Add some players for the game.",
            })}
          />
          <FormError error={errors.selectedTeams} />
        </form>
      </BackBlack>
    </>
  );
}
