import { captureException } from "@sentry/react";
import { useRouteError } from "react-router";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { NavBar } from "./components/ui/nav_bar";
import { BackBlack, BackWhite } from "./components/ui/backdrop";
import { MyText } from "./components/ui/text";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  captureException(error);

  // Why session auth?
  return (
    <SessionAuth>
      <NavBar logo home />
      <BackWhite />
      <BackBlack className="text-center">
        <MyText>Oops!</MyText>
        <MyText size="text" alt>
          Sorry, an unexpected error has occurred:
        </MyText>
        <MyText size="sm" alt>
          <i>{error.statusText || error.message}</i>
        </MyText>
      </BackBlack>
    </SessionAuth>
  );
}
