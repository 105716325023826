import {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { UseFormRegister, UseFormSetValue, useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import FormError from "@/components/ui/form_error";
import { NavBar } from "@/components/ui/nav_bar";
import { CircleButton } from "@/components/ui/button";
import { GameRes, GameType, searchGames } from "@/api/game";
import { Separator } from "@/components/ui/separator";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { ActionIsland } from "@/components/ui/action_island";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { MyText } from "@/components/ui/text";
import { Selector, SelectorSelect } from "@/components/ui/selector";

export type GameStateT = {
  name: string;
  type: string;
};

type gameSelectProps = {
  nextStep: (step: string) => void;
  gameState: MutableRefObject<GameStateT>;
};
export function GameTypeComp(props: gameSelectProps) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<GameStateT>({ defaultValues: props.gameState.current });

  const gameTypes = [
    {
      name: GameType.Tally,
      icon: "/images/tally_icon.svg",
      step: "submit",
      desc: "A simple count of scores.",
    },
    {
      name: GameType.Scorecard,
      icon: "/images/scorecard_icon.svg",
      step: "submit",
      desc: "A table of scores for games with scoresheets.",
    },
    // {
    //   name: GameType.Tournament,
    //   icon: "/images/tournament_icon.svg",
    //   step: "submit",
    //   desc: "A battle between players.",
    // },
  ];

  function submit(data: GameStateT) {
    props.gameState.current = data;
    let type = gameTypes.find((gt) => gt.name == data.type);
    if (type) props.nextStep(type.step);
  }

  return (
    <>
      <ActionIsland>
        <CircleButton icon={faArrowRight} form="theform" />
      </ActionIsland>

      <NavBar logo home />
      <BackWhite className="pb-8">
        <SearchInput register={register} setValue={setValue} />
        <FormError error={errors.name} />
      </BackWhite>
      <BackBlack>
        <form id="theform" onSubmit={handleSubmit(submit)}>
          <MyText>Game Setup</MyText>
          <Selector>
            {gameTypes.map((t) => (
              <SelectorSelect
                key={t.name}
                {...register("type", {
                  required: "A score type is required",
                })}
                value={t.name}
              >
                <div className="flex flex-col items-center px-4 py-8">
                  <img src={t.icon} className="h-8" />
                  <MyText>{t.name}</MyText>
                  <p className="text-xs text-center">{t.desc}</p>
                </div>
              </SelectorSelect>
            ))}
          </Selector>
          <div className="flex justify-center">
            <FormError error={errors.type} />
          </div>
        </form>
      </BackBlack>
    </>
  );
}

function SearchInput({
  register,
  setValue,
}: {
  register: UseFormRegister<GameStateT>;
  setValue: UseFormSetValue<GameStateT>;
}) {
  function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number,
  ): (...args: Parameters<T>) => void {
    let timeoutId: NodeJS.Timeout;

    return function (...args: Parameters<T>): void {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const searchRef = useRef<HTMLDivElement>(null);
  const [search, searchSetter] = useState<GameRes[]>([]);
  const [showSearch, showSearchSetter] = useState(false);
  // Hide search when user clicks off it
  useEffect(() => {
    function handleClickOff(event: MouseEvent) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        showSearchSetter(false);
      }
    }
    document.addEventListener("mousedown", handleClickOff);
    return () => {
      document.removeEventListener("mousedown", handleClickOff);
    };
  }, [searchRef]);

  async function _gameSearch(s: string) {
    try {
      const games = await searchGames(s);
      searchSetter(games);
      showSearchSetter(true);
    } catch {}
  }
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      _gameSearch(searchQuery);
    }, 500),
    [],
  );
  function gameSearch(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.currentTarget.value) searchSetter([]);
    else debouncedSearch(e.currentTarget.value);
  }
  function searchSelect(g: GameRes) {
    setValue("name", g.name);
    setValue("type", g.type);
    showSearchSetter(false);
  }

  return (
    <>
      <div className="mx-10">
        <Input
          form="theform"
          autoComplete="off"
          placeholder="Enter game name..."
          onFocus={() => showSearchSetter(true)}
          {...register("name", {
            required: "This field is required.",
            onChange: gameSearch,
          })}
        />
      </div>
      {search.length != 0 && showSearch && (
        <div
          ref={searchRef}
          className="font-main text-dgrey flex flex-col gap-1 p-2"
        >
          {search.map((g, i) => (
            <Fragment key={g.id}>
              {i != 0 && <Separator className="bg-lgrey" />}
              <div
                onClick={() => searchSelect(g)}
                className="flex justify-between py-1 px-2 cursor-pointer rounded-md hover:bg-lgrey"
              >
                <p>{g.name}</p>
                <p>{g.type}</p>
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
}
