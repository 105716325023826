import {
  faMobileScreen,
  faPencil,
  faRightFromBracket,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useRouteLoaderData } from "react-router";
import { signOut } from "supertokens-auth-react/recipe/passwordless";
import { UserRes } from "@/api/user";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { DoubleQ } from "@/components/ui/double_letters";
import { NavBar } from "@/components/ui/nav_bar";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { NewCard } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { MyText } from "@/components/ui/text";
import { share } from "@/share";
import { usePostHog } from "posthog-js/react";

export default function ProfileView() {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const loaderData = useRouteLoaderData("profilecheck") as UserRes;

  // TODO; make action
  async function logout() {
    posthog.reset();
    await signOut();
    navigate("/login");
  }

  return (
    <>
      <NavBar logo home />
      <BackWhite>
        <div className="flex flex-col items-center gap-2">
          <Avatar size="xl">
            <AvatarImage src={loaderData.avatarUrl} />
            <AvatarFallback>
              <DoubleQ size="lg" />
            </AvatarFallback>
          </Avatar>
          <div className="text-center">
            <p className="font-medium text-3xl">{loaderData.name}</p>
            <p className="pb-4 text-sm font-main text-grey">
              {loaderData.email}
            </p>
          </div>
        </div>
      </BackWhite>

      <BackBlack className="relative pt-8 px-16 sm:px-16">
        <NewCard className="shadow-none">
          <Button variant="ghost" asChild>
            <Link to="/profile/edit" viewTransition>
              <FontAwesomeIcon icon={faPencil} className="mr-2" /> Edit profile
            </Link>
          </Button>
          {!window.matchMedia("(display-mode: standalone)").matches && (
            <>
              <Separator />
              <Button variant="ghost" asChild>
                <Link to="/download" viewTransition>
                  <FontAwesomeIcon
                    icon={faMobileScreen}
                    className="text-lg mr-2"
                  />
                  Install
                </Link>
              </Button>
            </>
          )}
          {Boolean(navigator.share) && (
            <>
              <Separator />
              <Button
                variant="ghost"
                onClick={() =>
                  share("Check out Pocket Tally, your scoring companion.")
                }
              >
                <FontAwesomeIcon icon={faShare} className="mr-2" />
                Share
              </Button>
            </>
          )}
        </NewCard>
        <NewCard className="shadow-none">
          <Button variant="ghost" className="text-red" onClick={logout}>
            <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" />
            Logout
          </Button>
        </NewCard>

        <div className="absolute bottom-8 left-0 w-full flex justify-center">
          <Link to="/terms" className="px-1 border-r border-grey">
            <MyText size="xs" alt className="underline p-0">
              Terms of Service
            </MyText>
          </Link>
          <Link to="/privacy" className="px-1">
            <MyText size="xs" alt className="underline p-0">
              Privacy Policy
            </MyText>
          </Link>
        </div>
      </BackBlack>
    </>
  );
}
