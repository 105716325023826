import { ScorecardGroupRes } from "@/api/scorecard";
import { useRouteLoaderData } from "react-router";
import { getSummedScores, getTeamsMap } from "../trackers/utils";
import { TrackerStatsComp } from "../trackers/stats";

export function ScorecardStats() {
  const loaderData = useRouteLoaderData("gameroot") as ScorecardGroupRes;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = loaderData.scorecards.map((scorecard) =>
    getSummedScores(scorecard.scores),
  );

  return <TrackerStatsComp teamsMap={teamsMap} scores={scores} />;
}
