import { ActionFunctionArgs, redirect } from "react-router";
import { createTally, getTally, updateTally } from "./tally";
import { GameScorecardStore, GameStore } from "@/storage";
import { GameType } from "./game";
import {
  createScorecard,
  getLastUserScorecardTemplate,
  listScorecards,
  updateScorecard,
} from "./scorecard";

export type TrackerBase = {
  id: string;
  created_at: string;
  updated_at: string;
};

export async function getTracker(args: ActionFunctionArgs): Promise<any> {
  let gameID = args.params.gameID || "";
  let type = new GameStore().get(gameID)?.type;

  let data;
  switch (type) {
    case GameType.Tally:
      data = await getTally(args);
      break;
    case GameType.Scorecard:
      data = await listScorecards(args);
      break;
  }

  if (data) return data;
  throw Error(`Error loading ${type}.`);
}

export async function createTrackerAction(
  args: ActionFunctionArgs,
): Promise<Response> {
  return createTracker(args);
}
export async function createTracker(
  args: ActionFunctionArgs,
  groupID?: string,
): Promise<Response> {
  let gameID = args.params.gameID || "";
  if (!groupID) {
    groupID = args.params.groupID || "";
  }
  let type = new GameStore().get(gameID)?.type;

  let response;
  switch (type) {
    case GameType.Tally:
      response = await createTally(groupID);
      break;
    case GameType.Scorecard:
      // TODO; Get template ID from backend - last used template in this game
      let templateID = await getLastUserScorecardTemplate(gameID);
      if (!templateID) {
        templateID = new GameScorecardStore().get(gameID);
      }
      if (templateID) {
        response = await createScorecard(groupID, templateID);
      }
      break;
  }

  if (response && response.ok) {
    let id = (await response.json()).id;
    return redirect(`/games/${gameID}/groups/${groupID}/trackers/${id}`);
  }
  throw Error(`Error creating ${type}.`);
}

export async function updateTracker(
  args: ActionFunctionArgs,
): Promise<Response> {
  let gameID = args.params.gameID || "";
  let groupID = args.params.groupID || "";
  let trackerID = args.params.trackerID || "";
  let type = new GameStore().get(gameID)?.type;

  let response;
  switch (type) {
    case GameType.Tally:
      response = await updateTally(args);
      break;
    case GameType.Scorecard:
      response = await updateScorecard(args);
      break;
  }

  if (response && response.ok) {
    return redirect(
      `/games/${gameID}/groups/${groupID}/trackers/${trackerID}/celebrate`,
    );
  }
  throw Error(`Error updating ${type}.`);
}
