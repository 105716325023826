import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { UserRes } from "@/api/user";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

const avatarVariants = cva(
  "relative flex items-center shrink-0 overflow-hidden rounded-full bg-white border-black",
  {
    variants: {
      size: {
        sm: "h-10 w-10 border-2",
        md: "w-12 h-12 border-2",
        lg: "w-20 h-20 border-2",
        xl: "w-44 h-44 shadow-md shadow-white border-2",
      },
    },
    defaultVariants: {
      size: "sm",
    },
  },
);

export interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarVariants> {}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
  // React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
>(({ className, size, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarVariants({ size, className }))}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("w-full", className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center rounded-full bg-slate-100 dark:bg-slate-800",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };

export function AvatarTeam(props: { users: UserRes[]; className?: string }) {
  return (
    <div className={cn("flex justify-center items-center", props.className)}>
      {props.users.map((u) => (
        <Popover key={u.id}>
          <PopoverTrigger onClick={(e) => e.stopPropagation()}>
            <Avatar className="-m-1">
              <AvatarImage src={u.avatarUrl} />
            </Avatar>
          </PopoverTrigger>
          <PopoverContent>
            <p>{u.name}</p>
          </PopoverContent>
        </Popover>
      ))}
    </div>
  );
}
export function AvatarTeamLg(props: { users: UserRes[]; className?: string }) {
  return (
    <div className={cn("flex justify-center items-center", props.className)}>
      {props.users.map((u) => (
        <Popover key={u.id}>
          <PopoverTrigger onClick={(e) => e.stopPropagation()}>
            <Avatar size="md" className="-m-1.5">
              <AvatarImage src={u.avatarUrl} />
            </Avatar>
          </PopoverTrigger>
          <PopoverContent>
            <p>{u.name}</p>
          </PopoverContent>
        </Popover>
      ))}
    </div>
  );
}
export function AvatarTeamXl(props: { users: UserRes[]; className?: string }) {
  return (
    <div className={cn("flex justify-center items-center", props.className)}>
      {props.users.map((u) => (
        <Popover key={u.id}>
          <PopoverTrigger onClick={(e) => e.stopPropagation()}>
            <Avatar size="lg" className="-m-2">
              <AvatarImage src={u.avatarUrl} />
            </Avatar>
          </PopoverTrigger>
          <PopoverContent>
            <p>{u.name}</p>
          </PopoverContent>
        </Popover>
      ))}
    </div>
  );
}
