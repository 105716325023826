import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import React from "react";

const titleVariants = cva("px-2", {
  variants: {
    size: {
      xl: "text-4xl font-semibold tracking-tight",
      title: "text-lg font-semibold tracking-tight",
      text: "text-base",
      sm: "text-sm text-lgrey",
      xs: "text-xs text-lgrey",
    },
    alt: {
      true: "font-main",
      false: "font-alternates",
    },
  },
  defaultVariants: {
    size: "title",
    alt: false,
  },
});

export interface TitleProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof titleVariants> {}

export const MyText = React.forwardRef<HTMLParagraphElement, TitleProps>(
  ({ size, alt, className, ...props }, ref) => {
    return (
      <p
        ref={ref}
        className={cn(titleVariants({ size, alt, className }))}
        {...props}
      />
    );
  },
);
