import { ActionIsland } from "@/components/ui/action_island";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { CircleButton } from "@/components/ui/button";
import { LogoWords } from "@/components/ui/logo";
import { PostHogIdentify } from "@/posthog";
import ReloadPrompt from "@/reload_prompt";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Outlet, useNavigation } from "react-router";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

export function LoggedInAppRoot() {
  const navigation = useNavigation();

  return (
    <SessionAuth>
      <PostHogIdentify />
      <Outlet />
      <ReloadPrompt />
      {["submitting", "loading"].includes(navigation.state) && (
        <ActionIsland displayOverride>
          <CircleButton icon={faSpinner} />
        </ActionIsland>
      )}
    </SessionAuth>
  );
}

export function Login() {
  return (
    <>
      <BackWhite>
        <div className="flex flex-col items-center my-8">
          <LogoWords className="w-44" />
          <p className="italic text-sm font-medium font-main">
            Count every win...
          </p>
        </div>
      </BackWhite>
      <BackBlack className="px-0">
        <Outlet />
      </BackBlack>
    </>
  );
}
