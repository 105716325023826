import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlay,
  faPlus,
  faSpinner,
  faTableList,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-lg font-medium font-main cursor-pointer transition-colors drop-shadow-md disabled:pointer-events-none disabled:opacity-60",
  {
    variants: {
      variant: {
        default: "bg-orange-500 text-black",
        constructive: "text-white bg-redLight rounded-lg",
        destructive: "text-white bg-redDark",
        icon: "bg-white text-black border border-black shadow",
        iconAlt: "bg-blue-500 text-white",
        ghost: "text-white w-full justify-start",
        outline: "text-white border border-white",
      },
      size: {
        xs: "h-6 px-2 text-xs",
        sm: "h-8 px-3 text-sm",
        default: "h-8 px-4 text-sm",
        icon: "h-12 w-12 rounded-full shrink-0 text-xl",
        iconAlt: "h-10 w-10 shrink-0 text-xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };

export interface CircleButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconDefinition;
  link?: string;
}

export const CircleButton = ({
  icon,
  link,
  className,
  ...props
}: CircleButtonProps) => {
  let style = "";
  switch (icon) {
    case faPlus: {
      style = "text-2xl";
      break;
    }
    case faUserGroup: {
      style = "text-lg";
      break;
    }
    case faSpinner: {
      style = "animate-spin";
      break;
    }
    case faPlay: {
      style = "ml-0.5";
      break;
    }
    case faTableList: {
      style = "text-2xl";
      break;
    }
    case faCheck: {
      style = "ml-px";
      break;
    }
  }

  return (
    <Button
      variant="icon"
      size="icon"
      className={className}
      {...props}
      asChild={Boolean(link)}
    >
      {link ? (
        <Link to={link} viewTransition>
          <FontAwesomeIcon icon={icon} className={style} />
        </Link>
      ) : (
        <FontAwesomeIcon icon={icon} className={style} />
      )}
    </Button>
  );
};
