import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { NavBar } from "@/components/ui/nav_bar";
import { MyText } from "@/components/ui/text";
import clsx from "clsx";
import { ReactNode } from "react";
import { useNavigate } from "react-router";

function Para({ children, bold }: { children: ReactNode; bold?: boolean }) {
  return (
    <MyText size="xs" alt className={clsx(bold && "font-semibold")}>
      {children}
    </MyText>
  );
}
function List({ children }: { children: ReactNode }) {
  return (
    <ul className="list-disc list-inside ps-2 text-xs mt-2 font-main">
      {children}
    </ul>
  );
}

export default function Privacy() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar backFunc={() => navigate(-1)} logo />
      <BackWhite>
        <MyText>Privacy Policy</MyText>
      </BackWhite>
      <BackBlack>
        <MyText size="text" alt>
          Introduction
        </MyText>
        <Para>
          This Privacy Policy outlines what you can expect from us regarding the
          collection, use, and protection of your personal information when you
          use our website or apps.
        </Para>
        <Para>
          We use your personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy.
        </Para>

        <MyText size="text" alt>
          Last Updated
        </MyText>
        <Para>24 September 2024</Para>

        <MyText size="text" alt>
          What information we collect, use, and why
        </MyText>
        <Para bold>Personal Data</Para>
        <Para>
          When you sign up for the Service, we may ask you to provide certain
          personal information, which may include but is not limited to:
        </Para>
        <List>
          <li>Display Name</li>
          <li>Email Address</li>
          <li>Profile Picture</li>
        </List>
        <Para>We use this personal data to:</Para>
        <List>
          <li>
            Provide and Maintain the Service: This includes ensuring the
            functionality of the Service and monitoring its usage.
          </li>
          <li>
            Manage Your Account: Your personal data allows us to handle your
            registration and provide access to the features and functionalities
            available to registered users.
          </li>
          <li>
            Communicate with You: We may use your information to contact you via
            email or push notifications with updates, important information, or
            other relevant communications about the Service.
          </li>
        </List>
        <Para bold>Usage Data</Para>
        <Para>
          Usage Data is collected automatically when using the Service. This is
          used to better understand user behavior, improve our services, and
          enhance your overall experience. The types of Usage Data we collect
          include, but are not limited to:
        </Para>
        <List>
          <li>
            Device Information: Details about the device you use to access our
            services, such as the type of device, operating system, browser
            type, and version.
          </li>
          <li>
            IP Address: The Internet Protocol (IP) address assigned to your
            device when connecting to the internet.
          </li>
          <li>
            Log Information: Information about your interactions with our
            service, including access times, pages viewed, links clicked, and
            the length of time spent on certain pages.
          </li>
          <li>
            Location Data: General geographic location information, derived from
            your IP address or device settings, such as your city or country.
          </li>
          <li>
            Cookies and Tracking Technologies: We may use cookies, web beacons,
            and other tracking technologies to collect information about your
            activity on our site, such as your preferences, visits to specific
            sections of the service, or whether you have opened an email we
            sent.
          </li>
        </List>
        <Para bold>Sharing Data</Para>
        <Para>This information may be shared in the following situations:</Para>
        <List>
          <li>With other users: when they connect with you in the app.</li>
          <li>
            With Service Providers: We may share your personal data with Service
            Providers to operate and analyze the use of our Service.
          </li>
        </List>

        <MyText size="text" alt>
          Your Data Protection Rights
        </MyText>
        <Para>
          As a user of our services, you have certain rights regarding your
          personal data under applicable data protection laws. These rights may
          include:
        </Para>
        <List>
          <li>
            Access: The right to request access to the personal data we hold
            about you.
          </li>
          <li>
            Rectification: The right to request correction of inaccurate or
            incomplete personal data.
          </li>
          <li>
            Erasure: The right to request deletion of your personal data under
            certain circumstances.
          </li>
          <li>
            Restriction of Processing: The right to request that we limit the
            processing of your personal data in specific situations.
          </li>
          <li>
            Data Portability: The right to request a copy of your data in a
            commonly used format.
          </li>
        </List>
        <Para>
          For more detailed information on your data protection rights, you can
          visit the{" "}
          <a
            className="underline"
            href="https://ico.org.uk/global/privacy-notice/your-data-protection-rights/"
          >
            Information Commissioner’s Office (ICO) website
          </a>
        </Para>

        <MyText size="text" alt>
          How long we keep information
        </MyText>
        <Para>
          We will retain any data only for as long as is necessary for the
          purposes set out in this Privacy Policy.
        </Para>

        <MyText size="text" alt>
          Security
        </MyText>
        <Para>
          We take reasonable measures to help protect personal information from
          loss, theft, misuse and unauthorized access, disclosure, alteration
          and destruction.
        </Para>

        <MyText size="text" alt>
          Changes to this Privacy Policy
        </MyText>
        <Para>
          We may update our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </Para>
        <Para>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Para>

        <MyText size="text" alt>
          Contact Us
        </MyText>
        <Para>
          If you have any questions about this Privacy Policy, you can contact
          us:{" "}
          <a className="underline" href="mailto:contact@mail.pockettally.app">
            contact@mail.pockettally.app
          </a>
        </Para>
        <br />
      </BackBlack>
    </>
  );
}
