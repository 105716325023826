import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { NewCard } from "@/components/ui/card";
import { NavBar } from "@/components/ui/nav_bar";
import { Selector, SelectorSelect } from "@/components/ui/selector";
import { MyText } from "@/components/ui/text";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowUpFromBracket,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, redirect, useNavigate } from "react-router";

export const installCheck = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return redirect("/login");
  }
  return null;
};
export default function Download() {
  const navigate = useNavigate();

  const [selectedOS, setSelectedOS] = useState("");

  return (
    <>
      <NavBar backFunc={() => navigate(-1)} logo />

      <BackWhite></BackWhite>

      <BackBlack className="relative pt-8">
        <MyText size="text" alt className="text-center">
          Continue in the app for an enhanced experience. To install, follow
          these steps:
        </MyText>

        <Selector>
          <SelectorSelect
            name="os"
            value="ios"
            checked={selectedOS == "ios"}
            onChange={(e) => setSelectedOS(e.currentTarget.value)}
          >
            <div className="flex flex-col items-center px-4 py-8">
              <FontAwesomeIcon icon={faApple} className="text-3xl" />
              <MyText>iOS</MyText>
            </div>
          </SelectorSelect>
          <SelectorSelect
            name="os"
            value="android"
            checked={selectedOS == "android"}
            onChange={(e) => setSelectedOS(e.currentTarget.value)}
          >
            <div className="flex flex-col items-center px-4 py-8">
              <FontAwesomeIcon icon={faAndroid} className="text-3xl" />
              <MyText>Android</MyText>
            </div>
          </SelectorSelect>
        </Selector>

        {selectedOS && (
          <NewCard className="shadow-none">
            <MyText size="text" alt>
              <ol className="list-decimal ml-3">
                {selectedOS == "ios" && (
                  <>
                    <li>
                      Click on the{" "}
                      <FontAwesomeIcon
                        icon={faArrowUpFromBracket}
                        className="mx-1"
                      />{" "}
                      button
                    </li>
                    <li>
                      Scroll down and click on "Add to Home Screen{" "}
                      <FontAwesomeIcon
                        icon={faSquarePlus}
                        className="text-lg mx-1"
                      />
                      "
                    </li>
                  </>
                )}
                {selectedOS == "android" && (
                  <>
                    <li>Open Chrome browser</li>
                    <li>
                      Open the menu{" "}
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className="mx-1"
                      />
                    </li>
                    <li>Click "Install App" or "Add to Home Screen"</li>
                    <li>Follow the prompts to install</li>
                  </>
                )}
              </ol>
            </MyText>
          </NewCard>
        )}

        <div className="absolute bottom-8 left-0 w-full flex justify-center">
          <MyText size="xs" alt className="underline text-grey">
            <Link to="/login">Continue in the website</Link>
          </MyText>
        </div>
      </BackBlack>
    </>
  );
}
