import { TallyGroupRes } from "@/api/tally";
import { useParams, useRouteLoaderData } from "react-router";
import { TrackerCelebrateComp } from "../trackers/celebrate";
import { getTallyScores, getTeamsMap } from "../trackers/utils";

export function TallyCelebrate() {
  const params = useParams();
  const trackerID = params.trackerID || "";

  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;
  const loaderTally = loaderData.tallies.find((t) => t.id == trackerID);
  if (!loaderTally) throw Error("Tally not found.");

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = getTallyScores(loaderTally);

  return <TrackerCelebrateComp teamsMap={teamsMap} scores={scores} />;
}
