import { ScorecardTemplateRes, createScorecardTemplate } from "@/api/scorecard";
import { UserRes } from "@/api/user";
import { ActionIsland } from "@/components/ui/action_island";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { Button, CircleButton } from "@/components/ui/button";
import { NewCard, NewCardButton } from "@/components/ui/card";
import FormError from "@/components/ui/form_error";
import { Input } from "@/components/ui/input";
import { NavBar } from "@/components/ui/nav_bar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { MyText } from "@/components/ui/text";
import { Wizard, useWizard } from "@/components/ui/wizard";
import { GameScorecardStore, GameStore } from "@/storage";
import {
  faArrowRight,
  faCircleXmark,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useParams,
  useRouteLoaderData,
  useSubmit,
} from "react-router";

export async function scorecardTemplateAction({
  request,
  params,
}: ActionFunctionArgs): Promise<Response> {
  const body = await request.json();
  const gameID = params.gameID || "";

  let templateID;
  if (body.intent == "create") {
    // Create template
    templateID = await createScorecardTemplate(gameID, body.payload);
  } else {
    templateID = body.template_id;
  }

  // Register template locally to this game
  new GameScorecardStore().set(gameID, templateID);

  // Next create the group
  return redirect(`/games/${gameID}/groups/create`);
}

export function ScorecardTemplate() {
  // Every page knows it's own forward route(s)
  // Backwards navigation handled by the wizard
  const steps: { [id: string]: ReactNode } = {
    list: <ListScorecardTemplates />,
    create: <CreateScorecardTemplate />,
  };

  return <Wizard steps={steps} />;
}

function ListScorecardTemplates() {
  const { nextStep } = useWizard();
  const navSubmit = useSubmit();

  const me = useRouteLoaderData("profilecheck") as UserRes;
  const scorecardTemplates = useLoaderData() as ScorecardTemplateRes[];
  const params = useParams();
  const gameName = new GameStore().get(params.gameID || "")?.name;

  function pick(templateID: string) {
    navSubmit(
      { intent: "select", template_id: templateID },
      { method: "POST", encType: "application/json" },
    );
  }
  return (
    <>
      <ActionIsland>
        <CircleButton icon={faPencil} onClick={() => nextStep("create")} />
      </ActionIsland>

      <NavBar backTo="/games/create" logo home />
      <BackWhite>
        <MyText>{gameName}</MyText>
      </BackWhite>

      <BackBlack>
        <MyText>Community Templates</MyText>
        {scorecardTemplates.length == 0 && (
          <MyText size="sm" alt>
            There are no community created templates for this game yet. Create
            you own below.
          </MyText>
        )}
        {scorecardTemplates.map((st) => (
          <NewCard key={st.id}>
            <Table className="[&_*]:border-black">
              <TableHeader>
                <TableRow>
                  <TableHead></TableHead>
                  <TableHead className="flex justify-center">
                    <Avatar>
                      <AvatarImage src={me.avatarUrl} />
                    </Avatar>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {st.fields.map((f, i) => (
                  <TableRow key={i}>
                    <TableCell>{f.name}</TableCell>
                    <TableCell>
                      <div className="w-16" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <NewCardButton>
              <Button size="sm" onClick={() => pick(st.id)}>
                Pick
                <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
              </Button>
            </NewCardButton>
          </NewCard>
        ))}
      </BackBlack>
    </>
  );
}

type CategoryValues = {
  inputs: { value: string }[];
};

function CreateScorecardTemplate() {
  const { prevStep } = useWizard();
  const navSubmit = useSubmit();
  const params = useParams();
  const gameName = new GameStore().get(params.gameID || "")?.name;
  const me = useRouteLoaderData("profilecheck") as UserRes;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoryValues>({
    defaultValues: {
      inputs: [{ value: "" }], // Start with one input field
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "inputs",
    rules: { required: "Please add some categories.", minLength: 1 },
  });

  const onSubmit = (data: CategoryValues) => {
    navSubmit(
      { intent: "create", payload: data.inputs.map((x) => x.value) },
      { method: "POST", encType: "application/json" },
    );
  };

  return (
    <>
      <ActionIsland>
        <CircleButton icon={faArrowRight} form="theform" />
      </ActionIsland>

      <NavBar backFunc={() => prevStep()} logo home />
      <BackWhite>
        <MyText>{gameName}</MyText>
      </BackWhite>

      <BackBlack>
        <MyText>Create your own Template</MyText>
        <form id="theform" onSubmit={handleSubmit(onSubmit)}>
          <NewCard>
            <Table className="[&_*]:border-black">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <div className="w-20" />
                  </TableHead>
                  <TableHead className="flex justify-center">
                    <Avatar>
                      <AvatarImage src={me.avatarUrl} />
                    </Avatar>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {fields.map((f, i) => (
                  <TableRow key={f.id}>
                    <TableCell>
                      <Input
                        variant="dark"
                        underline
                        {...register(`inputs.${i}.value`, {
                          required: "This field is required.",
                        })}
                        placeholder="Enter category..."
                      />
                      <FormError error={errors.inputs?.[i]?.value} />
                    </TableCell>
                    <TableCell>
                      <div className="w-20" />
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="text-orange-500 text-xl cursor-pointer"
                        onClick={() => remove(i)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <NewCardButton>
              <Button
                type="button"
                size="sm"
                onClick={() => append({ value: "" })}
              >
                Add Category
              </Button>
            </NewCardButton>
          </NewCard>
        </form>
        <FormError error={errors.inputs?.root} />
      </BackBlack>
    </>
  );
}
