import { cn } from "@/lib/utils";

export function Logo(props: { className?: string }) {
  return (
    <img
      src="/brand/logo.svg"
      alt="pocket tally logo"
      className={cn("w-16 h-16 drop-shadow-md", props.className)}
    />
  );
}

export function LogoWords(props: { className?: string }) {
  return (
    <img
      src="/brand/logo_words.svg"
      alt="pocket tally logo"
      className={cn("w-20 drop-shadow", props.className)}
    />
  );
}
