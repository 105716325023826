import { CoinFlip } from "./tools/coin_flip";
import { Calculator } from "./tools/calculator";
import { TeamRes } from "@/api/game";
import { RandomTeam } from "./tools/random_team";

// const DiceToss = () => {
//   return (
//     <Drawer>
//       <DrawerTrigger className="p-3">
//         <FontAwesomeIcon icon={faDice} className="text-2xl" />
//       </DrawerTrigger>
//       <DrawerContent>
//         <DrawerHeader>
//           <DrawerTitle>Dice Toss</DrawerTitle>
//         </DrawerHeader>
//       </DrawerContent>
//     </Drawer>
//   );
// };

export default function GameTools({ teams }: { teams: TeamRes[] }) {
  return (
    <div className="flex justify-around items-center px-2 text-dgrey">
      <RandomTeam teams={teams} />
      <Calculator />
      <CoinFlip />
    </div>
  );
}
