import { CircleButton } from "@/components/ui/button";
import { NavBar } from "@/components/ui/nav_bar";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useParams, useRouteLoaderData, useSubmit } from "react-router";
import { GameStore } from "@/storage";
import { ScorecardGroupRes } from "@/api/scorecard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { AvatarTeam } from "@/components/ui/avatar";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { permissions } from "@/permissions";
import { ActionIsland } from "@/components/ui/action_island";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { MyText } from "@/components/ui/text";
import { getTeamsMap } from "../trackers/utils";
import GameTools from "../tools";
import { useCalculator } from "../tools/calculator";
import clsx from "clsx";

export default function ScorecardEdit() {
  const navSubmit = useSubmit();
  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";
  const trackerID = params.trackerID || "";

  const gameName = new GameStore().get(gameID)?.name;
  const loaderData = useRouteLoaderData("gameroot") as ScorecardGroupRes;
  const groupData = loaderData.group;
  const loaderScorecard = loaderData.scorecards.find((s) => s.id == trackerID);
  if (!loaderScorecard) throw Error("Scorecard not found.");
  const loaderScores = loaderScorecard.scores;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  // Teams in an order
  const teams = Object.keys(teamsMap);

  const fieldsMap = Object.assign(
    {},
    ...loaderScores.map((s) => ({ [s.field_id]: s.field_name })),
  );
  // Fields in an order
  const fields = [...new Set(loaderScores.map((s) => s.field_id))];

  const [scores, scoresSetter] = useState<number[][]>(
    fields.map((f) =>
      teams.map(
        (t) =>
          loaderScores.find((s) => s.field_id == f && s.team_id == t)?.score ||
          0,
      ),
    ),
  );

  function update(i: number, j: number, score: number) {
    let newScores = [...scores];
    newScores[i][j] = score;
    scoresSetter(newScores);
  }
  function submit(e: React.FormEvent) {
    e.preventDefault();

    const payload = fields.flatMap((f, i) =>
      teams.map((t, j) => ({
        field_id: f,
        team_id: t,
        score: scores[i][j],
      })),
    );

    navSubmit(
      { scores: payload },
      { method: "put", encType: "application/json" },
    );
  }

  const { isWiggleState, calculatorState } = useCalculator();
  const [wiggle, setWiggle] = isWiggleState;
  const [calculatorVal, setCalculatorVal] = calculatorState;

  return (
    <>
      {groupData.permission_level >= permissions.Manager && (
        <ActionIsland extra={<GameTools teams={loaderData.group.teams} />}>
          <CircleButton icon={faCheck} onClick={submit} />
        </ActionIsland>
      )}

      <NavBar
        backTo={`/games/${gameID}/groups/${groupID}/trackers`}
        logo
        home
      />
      <BackWhite>
        <MyText>{gameName}</MyText>
      </BackWhite>

      <BackBlack>
        <Table sticky>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              {teams.map((t) => (
                <TableHead key={t}>
                  <AvatarTeam users={teamsMap[t].users} />
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {fields.map((f, i) => (
              <TableRow key={f}>
                <TableCell>{fieldsMap[f]}</TableCell>
                {teams.map((t, j) => (
                  <TableCell key={`${f}${t}`}>
                    <Input
                      variant="dark"
                      underline
                      type="number"
                      size={scores[i][j].toString().length}
                      className={clsx(
                        "max-w-20 text-center w-auto inline-block p-1",
                        wiggle && "animate-wiggle ring-4 ring-orange-500",
                      )}
                      value={scores[i][j]}
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) => (e.target.value = scores[i][j].toString())}
                      onChange={(e) =>
                        update(i, j, parseInt(e.currentTarget.value))
                      }
                      onClick={(e) => {
                        if (wiggle) {
                          e.preventDefault();
                          e.stopPropagation();
                          e.currentTarget.blur();

                          setWiggle(false);
                          update(i, j, parseInt(calculatorVal));
                          setCalculatorVal("");
                        }
                      }}
                      readOnly={
                        groupData.permission_level < permissions.Manager
                      }
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </BackBlack>
    </>
  );
}
