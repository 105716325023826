import { CircleButton } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { faCircle, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";

export const CoinFlip = () => {
  const ref = useRef<HTMLDivElement>(null);
  const flip = () => {
    let n = Math.floor(Math.random() * 5) + 3;
    ref.current?.animate(
      [{ transform: `rotateY(0deg)` }, { transform: `rotateY(${180 * n}deg)` }],
      {
        duration: 1500,
        easing: "ease-out",
        fill: "forwards",
      },
    );
  };
  return (
    <Drawer>
      <DrawerTrigger className="p-3">
        <FontAwesomeIcon icon={faCircle} className="text-xl" />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Coin Flip</DrawerTitle>
        </DrawerHeader>
        <div className="flex justify-center">
          <div
            ref={ref}
            className="relative h-28 w-28 [transform-style:preserve-3d]"
          >
            <div className="absolute h-full w-full bg-blue-500 z-20 rounded-full drop-shadow-md [backface-visibility:hidden]">
              <div className="h-full flex justify-center items-center"></div>
            </div>
            <div className="absolute h-full w-full bg-orange-500 z-30 rounded-full drop-shadow-md [backface-visibility:hidden] [transform:rotateY(-180deg)]">
              <div className="h-full flex justify-center items-center"></div>
            </div>
          </div>
        </div>
        <DrawerFooter>
          <CircleButton icon={faRotate} onClick={flip} />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
