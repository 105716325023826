import { Button, CircleButton } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Input } from "@/components/ui/input";
import { faArrowRight, faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ReactNode, createContext, useContext, useState } from "react";

interface CalculatorContextType {
  isWiggleState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  calculatorState: [string, React.Dispatch<React.SetStateAction<string>>];
}
const CalculatorContext = createContext<CalculatorContextType | undefined>(
  undefined,
);

export const useCalculator = () => {
  const context = useContext(CalculatorContext);
  if (!context) {
    throw new Error("useCalculator must be used within a CalculatorProvider");
  }
  return context;
};

export const CalculatorProvider = ({ children }: { children: ReactNode }) => {
  const isWiggleState = useState(false);
  const calculatorState = useState("");

  return (
    <CalculatorContext.Provider value={{ isWiggleState, calculatorState }}>
      {children}
    </CalculatorContext.Provider>
  );
};

export const Calculator = () => {
  const { isWiggleState, calculatorState } = useCalculator();
  const [_, setWiggle] = isWiggleState;
  const [input, setInput] = calculatorState;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const calculate = (s: string) => {
    const sanitised = s.replace(/[^-+/*\d.]/g, "");
    if (!sanitised) {
      return "0";
    } else {
      return eval(sanitised).toString();
    }
  };

  const submit = () => {
    setInput(calculate(input));
    setWiggle(true);
    setDrawerOpen(false);
  };

  const Number = ({
    s,
    dark,
    onclick,
  }: {
    s: string;
    dark?: boolean;
    onclick?: () => void;
  }) => (
    <Button
      variant="iconAlt"
      size="iconAlt"
      className={clsx("", dark ? "bg-dgrey" : "bg-lgrey text-black")}
      onClick={() => {
        onclick !== undefined ? onclick() : setInput(input + s);
      }}
    >
      {s}
    </Button>
  );

  return (
    <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
      <DrawerTrigger className="p-3">
        <FontAwesomeIcon icon={faCalculator} className="text-2xl" />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Calculator</DrawerTitle>
        </DrawerHeader>
        <div className="flex justify-center">
          <div className="inline-grid auto-rows-fr grid-cols-4 gap-2">
            <Input
              inputMode="numeric"
              type="text"
              readOnly
              className="col-span-4 w-48"
              value={input}
              onChange={(e) => setInput(e.currentTarget.value)}
            />
            <Number s="1" />
            <Number s="2" />
            <Number s="3" />
            <Number s="+" dark />
            <Number s="4" />
            <Number s="5" />
            <Number s="6" />
            <Number s="-" dark />
            <Number s="7" />
            <Number s="8" />
            <Number s="9" />
            <Number s="*" dark />
            <Number s="=" dark onclick={() => setInput(calculate(input))} />
            <Number s="0" />
            <Number s="c" dark onclick={() => setInput("")} />
            <Number s="/" dark />
          </div>
        </div>
        <DrawerFooter>
          <CircleButton icon={faArrowRight} onClick={submit} />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
