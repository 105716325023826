import { cn } from "@/lib/utils";

export const AnimatedTick = ({ className }: { className?: string }) => {
  return (
    <div className="flex justify-center">
      <svg
        className={cn(className, "p-2 text-green aspect-square]")}
        viewBox="0 0 60 60"
      >
        <circle
          cx="30"
          cy="30"
          r="25"
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeDasharray="157"
          strokeDashoffset="157"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="157"
            to="0"
            dur="0.4s"
            begin="0.2s"
            fill="freeze"
          />
        </circle>
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="36"
          strokeDashoffset="36"
          d="M18 30 l10 10 l15 -15"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="36"
            to="0"
            dur="0.4s"
            begin="0.6s"
            fill="freeze"
          />
        </path>
      </svg>
    </div>
  );
};
