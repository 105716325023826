import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, redirect } from "react-router";
import { RouterProvider } from "react-router/dom";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import * as reactRouterDom from "react-router";

import "./fonts.css";
import "./index.css";
import "./sentry";
import "./supertokens";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { LoggedInAppRoot, Login } from "./routes/root";
import ErrorPage from "./error-page";
import Home from "./routes/home";
import FriendsList, { friendsLoader } from "./routes/friends/list";
import ProfileView from "./routes/profile/view";
import ProfileEdit from "./routes/profile/edit";
import GameCreate from "./routes/games/create/game_wizard";
import {
  addFriend,
  createProfile,
  editProfile,
  fulfilFriend,
  getFriends,
  profileCheckLoader,
} from "./api/user";
import { createGame, deleteGroup, getGames } from "./api/game";
import {
  GroupCreate,
  groupCreateAction,
} from "./routes/games/create/group_wizard";
import ProfileCreate from "./routes/profile/create";
import { FriendsCreate, friendsCreateAction } from "./routes/friends/create";
import { createTrackerAction, getTracker, updateTracker } from "./api/tracker";
import Privacy from "./routes/privacy";
import Terms from "./routes/terms";
import {
  ScorecardTemplate,
  scorecardTemplateAction,
} from "./routes/games/create/scorecard_wizard";
import { listScorecardTemplates } from "./api/scorecard";
import { TrackerHistory } from "./routes/games/trackers/history";
import { TrackerEdit } from "./routes/games/trackers/edit";
import { TrackerCelebrate } from "./routes/games/trackers/celebrate";
import { TrackerLeaderboard } from "./routes/games/trackers/leaderboard";
import { PostHogPageviewTracker } from "./posthog";
import { TrackerStats } from "./routes/games/trackers/stats";
import Download, { installCheck } from "./routes/download";

const shouldRevalidate: reactRouterDom.ShouldRevalidateFunction = ({
  actionResult,
}) => {
  if (actionResult) {
    if (actionResult?.ok) {
      if (actionResult?.skipRevalidation) return false;
      else return true;
    } else return false;
  }
  return true;
};

const router = createBrowserRouter([
  {
    path: "/fetcher/reset",
    // This is revalidating for some reason
    action: (): any => {
      return { ok: false };
    },
  },
  {
    element: <PostHogPageviewTracker />,
    children: [
      {
        path: "/download",
        element: <Download />,
        loader: installCheck,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        element: <Login />,
        children: [
          ...getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
            PasswordlessPreBuiltUI,
          ]).map((r) => r.props),
        ],
      },
      {
        element: <LoggedInAppRoot />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/profile/create",
            element: <ProfileCreate />,
            action: createProfile,
          },
          {
            id: "profilecheck",
            loader: profileCheckLoader,
            children: [
              {
                path: "/",
                loader: () => redirect("/home"),
              },
              {
                path: "/home",
                loader: getGames,
                element: <Home />,
              },
              {
                path: "/profile",
                element: <ProfileView />,
              },
              {
                path: "/profile/edit",
                element: <ProfileEdit />,
                action: editProfile,
              },
              {
                path: "/friends",
                element: <FriendsList />,
                loader: friendsLoader,
                shouldRevalidate,
              },
              {
                path: "/friends/add",
                action: addFriend,
              },
              {
                path: "/friends/fulfil",
                action: fulfilFriend,
              },
              {
                path: "/friends/create",
                element: <FriendsCreate />,
                action: friendsCreateAction,
              },
              {
                path: "/games/create",
                element: <GameCreate />,
                action: createGame,
              },
              {
                path: "/games/:gameID",
                children: [
                  {
                    path: "scorecard-template",
                    element: <ScorecardTemplate />,
                    loader: listScorecardTemplates,
                    action: scorecardTemplateAction,
                  },
                  {
                    path: "groups/create",
                    element: <GroupCreate />,
                    loader: getFriends,
                    action: groupCreateAction,
                  },
                  {
                    id: "gameroot",
                    path: "groups/:groupID",
                    loader: getTracker,
                    action: deleteGroup,
                    children: [
                      {
                        path: "trackers/:trackerID",
                        element: <TrackerEdit />,
                        action: updateTracker,
                      },
                      {
                        path: "trackers/:trackerID/celebrate",
                        element: <TrackerCelebrate />,
                      },
                      {
                        path: "trackers",
                        element: <TrackerLeaderboard />,
                        action: createTrackerAction,
                      },
                      {
                        path: "trackers/stats",
                        element: <TrackerStats />,
                      },
                      {
                        path: "trackers/history",
                        element: <TrackerHistory />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  capture_pageview: false,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <RouterProvider router={router} />
    </PostHogProvider>
  </React.StrictMode>,
);
