import { TeamRes } from "@/api/game";
import { cn } from "@/lib/utils";
import { MyText } from "./text";
import { AvatarTeam } from "./avatar";

export const LeaderBoard = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col gap-px bg-dgrey", className)} {...props} />
);

export interface LeaderBoardRankProps
  extends React.HTMLAttributes<HTMLDivElement> {
  rank: number;
  team: TeamRes;
  score: number;
}
export const LeaderBoardRank = ({
  rank,
  team,
  score,
}: LeaderBoardRankProps) => (
  <div className="flex items-center justify-between bg-black py-2">
    <div className="flex items-center gap-4">
      <div className="text-center w-10">
        <MyText>{rank}</MyText>
      </div>
      <AvatarTeam users={team.users} />
    </div>
    <div className="bg-white text-black rounded-full w-10 h-10 flex justify-center items-center">
      <MyText>{score}</MyText>
    </div>
  </div>
);
