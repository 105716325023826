import { TeamRes } from "@/api/game";
import { AvatarTeamLg } from "@/components/ui/avatar";
import { CircleButton } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  faChevronUp,
  faRotate,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useRef } from "react";

export const RandomTeam = ({ teams }: { teams: TeamRes[] }) => {
  const teamRefs = useRef<(HTMLDivElement | null)[]>([]);

  const spin = () => {
    const randomClicks = Array.from({ length: 5 }, () =>
      Math.round(Math.random() * teams.length),
    ).reduce((sum, current) => sum + current, 0);

    teamRefs.current.forEach(
      (t) =>
        t?.animate(
          [
            { transform: `translateX(0)` },
            { transform: `translateX(${100 * randomClicks}%)` },
          ],
          {
            duration: 1500,
            easing: "ease-out",
            fill: "forwards",
          },
        ),
    );
  };

  // We need enough to complete the spin
  const displayedTeams = Array(teams.length * 5 + 3)
    .fill(teams)
    .flat();

  return (
    <Drawer>
      <DrawerTrigger className="p-3">
        <FontAwesomeIcon icon={faUserGroup} className="text-xl" />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Pick a Team</DrawerTitle>
        </DrawerHeader>
        <div className="relative flex flex-row-reverse overflow-hidden">
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center">
            <div className="h-1 basis-full bg-black" />
          </div>
          {displayedTeams.map((team, i) => (
            <div key={i} className="relative h-20 basis-1/3 shrink-0 grow-0">
              <div
                ref={(el) => (teamRefs.current[i] = el)}
                className="absolute top-0 h-20 w-full flex justify-center items-center z-20"
              >
                <AvatarTeamLg users={team.users} />
              </div>
              <div className="absolute top-0 h-20 w-full flex justify-center items-center z-10">
                <div
                  className={clsx(
                    "w-16 h-16 rounded-full shadow",
                    i === 1 ? "bg-blue-500" : "bg-lgrey",
                  )}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <FontAwesomeIcon icon={faChevronUp} className="text-3xl" />
        </div>

        <DrawerFooter>
          <CircleButton icon={faRotate} onClick={spin} />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
