import React from "react";
import { useNavigation } from "react-router";
import { useRegisterSW } from "virtual:pwa-register/react";

export interface ActionIslandProps
  extends React.HTMLAttributes<HTMLDivElement> {
  extra?: React.ReactNode;
  displayOverride?: boolean;
}

export const ActionIsland = React.forwardRef<HTMLDivElement, ActionIslandProps>(
  ({ children, extra, displayOverride, ...props }, ref) => {
    const {
      needRefresh: [needRefresh, _],
    } = useRegisterSW();
    const navigation = useNavigation();

    // Hide other action islands when reload available or loading
    const display = Boolean(
      displayOverride ||
        !(needRefresh || ["submitting", "loading"].includes(navigation.state)),
    );

    return (
      <>
        {display ? (
          <div className="pointer-events-none w-full fixed bottom-10 left-0 flex justify-center z-50">
            <div className="pointer-events-none grow max-w-screen-sm px-4 flex flex-row-reverse gap-2">
              <div
                className="pointer-events-auto flex gap-2 p-2 bg-blue-500 rounded-full shadow [&_*]:shadow-none"
                ref={ref}
                {...props}
              >
                {children}
              </div>
              {extra && (
                <div className="pointer-events-auto bg-white rounded-full shadow-downlight grow content-center text-center">
                  {extra}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  },
);
