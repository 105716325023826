import { ActionFunctionArgs, redirect } from "react-router";
import { apiFetch } from "./utils";

export type UserRes = {
  id: string;
  name: string;
  email?: string;
  avatarUrl: string;
};

export async function getDefaultAvatars(): Promise<string[]> {
  const response = await apiFetch("GET", "/avatars");
  return await response.json();
}

// Check if the profile has been completed
// If not redirect to profile edit
export async function profileCheckLoader() {
  const profile = await getProfile();
  if (!profile) return redirect("/profile/create");
  return profile;
}

export async function getProfile(): Promise<UserRes | null> {
  const response = await apiFetch("GET", "/profile");
  if (response.ok) return await response.json();
  if (response.status == 404) return null;
  throw Error("Error loading profile");
}

export async function createProfile({
  request,
}: ActionFunctionArgs): Promise<Response> {
  const formData = await request.formData();
  const response = await apiFetch("POST", "/profile", formData, true);
  let err = "Something went wrong!";
  if (response.ok) return redirect("/games/create");
  else if (response.status == 400) err = (await response.json()).message;

  throw Error(err);
}

export async function editProfile({
  request,
}: ActionFunctionArgs): Promise<Response> {
  const formData = await request.formData();
  const response = await apiFetch("PUT", "/profile", formData, true);
  let err = "Something went wrong!";
  if (response.ok) return redirect("/profile");
  else if (response.status == 400) err = (await response.json()).message;

  throw Error(err);
}

export async function getFriends(): Promise<UserRes[]> {
  const response = await apiFetch("GET", "/friends");
  return await response.json();
}

export async function getFriendRequests(): Promise<UserRes[]> {
  const response = await apiFetch("GET", "/friends/requests");
  return await response.json();
}

export async function createFriend({
  request,
}: ActionFunctionArgs): Promise<Response> {
  const formData = await request.formData();
  const response = await apiFetch("POST", "/friends", formData, true);
  let err = "Something went wrong!";
  if (response.ok) return response;
  else if (response.status == 400) err = (await response.json()).message;

  throw Error(err);
}

export async function addFriend({
  request,
}: ActionFunctionArgs): Promise<{ ok: boolean; error?: string }> {
  const data = await request.json();
  const response = await apiFetch("POST", "/friends/add", data);
  if (response.ok) return { ok: true };
  else if (response.status == 400)
    return { ok: false, error: "Invalid email." };
  else if (response.status == 404)
    return {
      ok: false,
      error: "User does not exist. Ask them to sign up first!",
    };

  throw response;
}

export async function fulfilFriend({ request }: ActionFunctionArgs): Promise<{
  ok: boolean;
  skipRevalidation?: boolean;
  error?: string;
}> {
  const data = await request.json();
  const response = await apiFetch("POST", "/friends/fulfil", data);
  if (response.ok) return { ok: true, skipRevalidation: true };
  else if (response.status == 400)
    return { ok: false, error: "Invalid email." };
  else if (response.status == 409)
    return {
      ok: false,
      error:
        "User already exists. Merging accounts is not currently supported, sorry for any inconvenience.",
    };

  throw response;
}
