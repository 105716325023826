import * as React from "react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const inputVariants = cva(
  "flex h-10 w-full rounded-lg px-3 py-2 text-base font-main focus-visible:outline-none",
  {
    variants: {
      variant: {
        light: "bg-white text-black ring-orange-300",
        dark: "bg-dgrey text-white ring-orange-700",
      },
      underline: {
        false: "ring-2 focus-visible:ring-4 focus-visible:ring-orange-500",
        true: "focus-visible:shadow-down",
      },
    },
    defaultVariants: {
      variant: "light",
      underline: false,
    },
  },
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ variant, underline, className, ...props }, ref) => {
    return (
      <input
        className={cn(inputVariants({ variant, underline, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
