import { ReactNode } from "react";
import { CircleButton } from "./button";
import { faChevronLeft, faHome } from "@fortawesome/free-solid-svg-icons";
import { Logo, LogoWords } from "./logo";

export const NavBar = (props: {
  logoWords?: boolean;
  backFunc?: () => void;
  backTo?: string;
  logo?: boolean;
  home?: boolean;
  children?: ReactNode;
}) => {
  return (
    <>
      <div className="h-8" />
      <div className="pt-4 h-[5.5rem] bg-white sticky top-0 z-20">
        <div className="px-4 sm:px-8 flex justify-between">
          {props.logoWords && <LogoWords />}
          {props.backFunc && (
            <CircleButton icon={faChevronLeft} onClick={props.backFunc} />
          )}
          {props.backTo && (
            <CircleButton icon={faChevronLeft} link={props.backTo} />
          )}
          {!(props.backFunc || props.backTo) && <div />}
          <div className="flex gap-1">
            {props.children}
            {props.home && <CircleButton icon={faHome} link="/home" />}
          </div>
        </div>
        {props.logo && (
          <Logo className="absolute top-3 right-2/4 translate-x-2/4" />
        )}
      </div>
    </>
  );
};
