import { CircleButton } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { GameType } from "@/api/game";
import { GameStore } from "@/storage";
import { useParams, useSubmit } from "react-router";
import { TallyLeaderboard } from "../tally/leaderboard";
import { ScorecardLeaderboard } from "../scorecard/leaderboard";
import { ActionIsland } from "@/components/ui/action_island";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { NavBar } from "@/components/ui/nav_bar";
import { permissions } from "@/permissions";
import {
  faTrash,
  faPlus,
  faCheck,
  faXmark,
  faPieChart,
} from "@fortawesome/free-solid-svg-icons";
import { TeamCelebrate } from "@/components/ui/team_celebrate";
import { MyText } from "@/components/ui/text";
import { Scores, TeamMap, getTeamTotals, getTeamWins } from "./utils";
import { LeaderBoard, LeaderBoardRank } from "@/components/ui/leaderboard";
import { Switch } from "@/components/ui/switch";
import { useState } from "react";
import { ConfettiDropCanvas } from "@/components/ui/confetti";
import { usePostHog } from "posthog-js/react";

export function TrackerLeaderboard() {
  const params = useParams();
  const gameID = params.gameID || "";

  let type = new GameStore().get(gameID)?.type;

  switch (type) {
    case GameType.Tally:
      return <TallyLeaderboard />;
    case GameType.Scorecard:
      return <ScorecardLeaderboard />;
  }
}

type TrackerLeaderboardCompProps = {
  permission_level: number;
  teamsMap: TeamMap;
  scores: Scores[];
};
export function TrackerLeaderboardComp({
  permission_level,
  teamsMap,
  scores,
}: TrackerLeaderboardCompProps) {
  const posthog = usePostHog();
  const navSubmit = useSubmit();

  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";

  const g = new GameStore().get(gameID);
  const gameName = g?.name;
  const [isTotals, setIsTotals] = useState(false);

  const wins = getTeamWins(scores);
  const totals = getTeamTotals(scores);
  const stat = isTotals ? totals : wins;
  const sortedStat = stat.sort((a, b) => (a.stat > b.stat ? -1 : 1));

  async function newScore() {
    posthog?.capture(`${g?.type.toLowerCase()}_create`);
    navSubmit(null, {
      method: "POST",
      action: `/games/${gameID}/groups/${groupID}/trackers`,
    });
  }

  async function deleteG() {
    posthog?.capture(`${g?.type.toLowerCase()}_delete`);
    navSubmit(null, {
      method: "POST",
      action: `/games/${gameID}/groups/${groupID}`,
    });
  }
  const deleteDrawer = () => {
    return (
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Are you absolutely sure?</DrawerTitle>
          <DrawerDescription>
            This action cannot be undone. This will permanently delete this
            group and the scores within.
          </DrawerDescription>
        </DrawerHeader>
        <DrawerFooter>
          <DrawerClose asChild>
            <CircleButton icon={faXmark} />
          </DrawerClose>
          <CircleButton icon={faCheck} onClick={deleteG} />
        </DrawerFooter>
      </DrawerContent>
    );
  };

  return (
    <>
      {permission_level >= permissions.Manager && (
        <ActionIsland>
          {permission_level >= permissions.Admin && (
            <Drawer>
              <DrawerTrigger asChild>
                <CircleButton icon={faTrash} className="text-red border-red" />
              </DrawerTrigger>
              {deleteDrawer()}
            </Drawer>
          )}
          <CircleButton icon={faPlus} onClick={newScore} />
        </ActionIsland>
      )}

      <NavBar logo home>
        <CircleButton
          icon={faPieChart}
          link={`/games/${gameID}/groups/${groupID}/trackers/stats`}
        />
      </NavBar>

      <BackWhite className="relative">
        <ConfettiDropCanvas />
        <TeamCelebrate
          team={teamsMap[sortedStat[0].team_id].users}
          score={sortedStat[0].stat}
        />
        <div className="flex justify-between">
          <MyText>{gameName}</MyText>
          <div className="flex items-center">
            <MyText size="text" alt>
              Wins
            </MyText>
            <Switch checked={isTotals} onCheckedChange={setIsTotals} />
            <MyText size="text" alt>
              Totals
            </MyText>
          </div>
        </div>
      </BackWhite>

      <BackBlack>
        {isTotals ? <MyText>Total Score</MyText> : <MyText>Wins</MyText>}
        <LeaderBoard>
          {sortedStat.map((s, i) => (
            <LeaderBoardRank
              key={i}
              rank={i + 1}
              team={teamsMap[s.team_id]}
              score={s.stat}
            />
          ))}
        </LeaderBoard>
      </BackBlack>
    </>
  );
}
