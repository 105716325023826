import { cn } from "@/lib/utils";
import React from "react";

export const Selector = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        "grid auto-rows-fr grid-cols-2 gap-px my-8 bg-dgrey",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
Selector.displayName = "Selector";

export const SelectorSelect = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ children, className, ...props }, ref) => {
  return (
    <label className="bg-black relative cursor-pointer">
      <input type="radio" className="hidden peer" ref={ref} {...props} />
      {children}
      <div className="absolute bottom-0 px-8 w-0 peer-checked:w-full transition-all">
        <div className="bg-orange-500 rounded-full h-2" />
      </div>
    </label>
  );
});
SelectorSelect.displayName = "SelectorSelect";
