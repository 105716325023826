import { ProfileForm } from "@/routes/profile/form";
import { NavBar } from "@/components/ui/nav_bar";
import { useLocation } from "react-router";
import { useWizard } from "@/components/ui/wizard";

export default function GroupAddFriend() {
  let location = useLocation();
  const { prevStep } = useWizard();

  return (
    <>
      <NavBar backFunc={prevStep} logo />

      <ProfileForm
        action={location.pathname}
        callback={prevStep}
        btnTxt="Add"
      />
    </>
  );
}
