import { ReactNode, useState } from "react";
import { UserRes, createFriend } from "@/api/user";
import { createGroup } from "@/api/game";
import {
  ActionFunctionArgs,
  useRouteLoaderData,
  useSubmit,
} from "react-router";
import { createTracker } from "@/api/tracker";
import { Wizard } from "@/components/ui/wizard";
import GroupAddFriend from "./add_friend";
import PlayerSelect from "./team_select";
import { usePostHog } from "posthog-js/react";

export async function groupCreateAction(
  args: ActionFunctionArgs,
): Promise<Response> {
  const contentType = args.request.headers.get("Content-Type");

  if (contentType?.includes("multipart/form-data")) {
    return await createFriend(args);
  } else {
    // (contentType?.includes("application/json"))

    // Create group
    const gameID = args.params.gameID || "";
    const groupID = await createGroup(gameID, await args.request.json());

    // Create the first tracker
    return createTracker(args, groupID);
  }
}

export function GroupCreate() {
  const posthog = usePostHog();
  const navSubmit = useSubmit();

  // State required to create the game
  const userData = useRouteLoaderData("profilecheck") as UserRes;
  const teamsState = useState<UserRes[][]>([[userData]]);

  // TODO; Add friend created via action to teams
  // Which team to add them to?

  // Creates the teams and game with the backend then redirects
  async function submit() {
    posthog?.capture("group_create");
    // Create teams - exclude empty teams
    let teams;
    teams = teamsState[0]
      .filter((t) => t.length != 0)
      .map((t) => t.map((u) => u.id));
    navSubmit(teams, { method: "POST", encType: "application/json" });
  }

  const steps: { [id: string]: ReactNode } = {
    teams: <PlayerSelect teamsState={teamsState} />,
    addFriend: <GroupAddFriend />,
  };

  return <Wizard steps={steps} submit={submit} />;
}
