import { TallyGroupRes } from "@/api/tally";
import { AvatarTeam } from "@/components/ui/avatar";
import { Button, CircleButton } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { faCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams, useRouteLoaderData, useSubmit } from "react-router";
import { NewCard } from "@/components/ui/card";
import { NavBar } from "@/components/ui/nav_bar";
import { GameStore } from "@/storage";
import { TeamRes } from "@/api/game";
import { Slider } from "@/components/ui/slider";
import { ActionIsland } from "@/components/ui/action_island";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { MyText } from "@/components/ui/text";
import { Separator } from "@/components/ui/separator";
import { getTeamsMap } from "../trackers/utils";
import GameTools from "../tools";
import { CalculatorProvider, useCalculator } from "../tools/calculator";
import clsx from "clsx";

function Score({
  team,
  init_score,
  update,
}: {
  team: TeamRes;
  init_score: number;
  update: (n: number) => void;
}) {
  const { isWiggleState, calculatorState } = useCalculator();
  const [wiggle, setWiggle] = isWiggleState;
  const [calculatorVal, setCalculatorVal] = calculatorState;

  const [score, scoreSetter] = useState(init_score);
  useEffect(() => {
    update(score);
  }, [score]);

  const [sliderVal, sliderValSetter] = useState([0]);
  const [isReleased, isReleasedSetter] = useState(false);
  function sliderChange(value: number[]) {
    sliderValSetter(value);
    isReleasedSetter(false);
  }
  function sliderCommit() {
    isReleasedSetter(true);
  }

  useEffect(() => {
    if (isReleased && sliderVal[0] != 0) {
      const animation = setTimeout(() => {
        sliderValSetter((prev) => [prev[0] - 1]);
        scoreSetter((prev) => prev + 1);
      }, 15);

      if (sliderVal[0] == 0) {
        clearTimeout(animation);
      }
      return () => clearTimeout(animation);
    }
  }, [isReleased, sliderVal]);

  return (
    <NewCard>
      <AvatarTeam users={team.users} />
      <Separator className="my-3" />
      <div className="flex gap-2 items-stretch">
        <div>
          {sliderVal[0] == 0 || isReleased ? (
            <Input
              variant="dark"
              type="number"
              className={clsx(
                "h-full text-center text-3xl ring-grey",
                wiggle && "animate-wiggle ring-4 ring-orange-500",
              )}
              value={score}
              onChange={(e) => scoreSetter(parseInt(e.currentTarget.value))}
              onClick={(e) => {
                if (wiggle) {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.blur();

                  setWiggle(false);
                  scoreSetter(parseInt(calculatorVal));
                  setCalculatorVal("");
                }
              }}
            />
          ) : (
            <Input
              variant="dark"
              type="text"
              className={clsx(
                "h-full text-center text-3xl ring-grey",
                wiggle && "animate-wiggle",
              )}
              value={`+${sliderVal}`}
            />
          )}
        </div>
        <div className="flex flex-col justify-around h-24">
          <Button
            type="button"
            variant="iconAlt"
            size="iconAlt"
            className="bg-orange-700 text-dgrey"
            onClick={() => scoreSetter(score + 1)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button
            type="button"
            variant="iconAlt"
            size="iconAlt"
            className="bg-orange-700 text-dgrey"
            onClick={() => scoreSetter(score - 1)}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </div>
      </div>
      <Slider
        className="pt-4"
        value={sliderVal}
        min={0}
        max={50}
        onValueChange={sliderChange}
        onValueCommit={sliderCommit}
      />
    </NewCard>
  );
}

export function TallyEdit() {
  const navSubmit = useSubmit();

  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";
  const trackerID = params.trackerID || "";

  const gameName = new GameStore().get(gameID)?.name;
  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;
  const loaderTally = loaderData.tallies.find((t) => t.id == trackerID);
  if (!loaderTally) throw Error("Tally not found.");
  const loaderScores = loaderTally.scores;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const [scores, scoresSetter] = useState<number[]>(
    loaderScores.map((t) => t.score),
  );

  function update(i: number, score: number) {
    let newScores = [...scores];
    newScores[i] = score;
    scoresSetter(newScores);
  }

  function submit(e: React.FormEvent) {
    e.preventDefault();

    const payload = loaderScores.map((t, i) => ({
      team: t.team_id,
      score: scores[i],
    }));

    navSubmit(
      { scores: payload },
      { method: "put", encType: "application/json" },
    );
  }

  return (
    <>
      <CalculatorProvider>
        <ActionIsland extra={<GameTools teams={loaderData.group.teams} />}>
          <CircleButton icon={faCheck} onClick={submit} form="theform" />
        </ActionIsland>

        <NavBar
          backTo={`/games/${gameID}/groups/${groupID}/trackers`}
          logo
          home
        />
        <BackWhite>
          <MyText>{gameName}</MyText>
        </BackWhite>

        <BackBlack className="pt-8">
          <form id="theform">
            <div className="grid auto-rows-fr grid-cols-2 gap-x-4 gap-y-6">
              {loaderScores.map((t, i) => (
                <div key={i} className="relative">
                  {/*
                {loaderScores.length > 2
                  ? i > 0 &&
                    i % 2 == 0 && (
                      <Versus
                        size="lg"
                        className="absolute z-20 top-0 right-0 translate-x-10 -translate-y-10"
                      />
                    )
                  : i == 0 && (
                      <Versus
                        size="lg"
                        className="absolute z-20 bottom-0 right-0 translate-x-10 translate-y-10"
                      />
                    )}
                */}
                  <Score
                    team={teamsMap[t.team_id]}
                    init_score={scores[i]}
                    update={(n) => update(i, n)}
                  />
                </div>
              ))}
            </div>
          </form>
        </BackBlack>
      </CalculatorProvider>
    </>
  );
}
