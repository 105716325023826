import { TallyGroupRes } from "@/api/tally";
import { useRouteLoaderData } from "react-router";
import { TrackerHistoryComp } from "../trackers/history";
import { getTallyScores, getTeamsMap } from "../trackers/utils";

export function TallyHistory() {
  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;

  const teamsMap = getTeamsMap(loaderData.group.teams);
  const scores = loaderData.tallies.map((tally) => getTallyScores(tally));

  return (
    <TrackerHistoryComp
      trackers={loaderData.tallies}
      groupData={loaderData.group}
      teamsMap={teamsMap}
      scores={scores}
    />
  );
}
