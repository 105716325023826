import { AvatarTeam } from "@/components/ui/avatar";
import { NavBar } from "@/components/ui/nav_bar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { permissions } from "@/permissions";
import { GameStore } from "@/storage";
import { faEye, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router";
import { GameType, GroupRes } from "@/api/game";
import { TallyHistory } from "../tally/history";
import { ScorecardHistory } from "../scorecard/history";
import { TrackerBase } from "@/api/tracker";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { MyText } from "@/components/ui/text";
import { Scores, TeamMap } from "./utils";

export function TrackerHistory() {
  const params = useParams();
  const gameID = params.gameID || "";

  let type = new GameStore().get(gameID)?.type;

  switch (type) {
    case GameType.Tally:
      return <TallyHistory />;
    case GameType.Scorecard:
      return <ScorecardHistory />;
  }
}

type TrackerHistoryCompProps = {
  trackers: TrackerBase[];
  groupData: GroupRes;
  teamsMap: TeamMap;
  scores: Scores[];
  allowRead?: boolean;
};
export function TrackerHistoryComp({
  trackers,
  groupData,
  teamsMap,
  scores,
  allowRead,
}: TrackerHistoryCompProps) {
  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";

  const gameName = new GameStore().get(gameID)?.name;

  return (
    <>
      <NavBar
        backTo={`/games/${gameID}/groups/${groupID}/trackers/stats`}
        logo
        home
      />
      <BackWhite>
        <MyText>{gameName}</MyText>
      </BackWhite>

      <BackBlack>
        <MyText>History</MyText>
        <Table sticky>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              {scores[0].map((s) => (
                <TableHead key={s.team_id}>
                  <div className="inline-block relative">
                    <AvatarTeam users={teamsMap[s.team_id].users} />
                  </div>
                </TableHead>
              ))}
              {(groupData.permission_level >= permissions.Manager ||
                allowRead) && <TableHead></TableHead>}
            </TableRow>
          </TableHeader>
          <TableBody>
            {trackers.map((tracker, i) => (
              <TableRow key={tracker.id}>
                <TableCell>
                  {new Date(tracker.created_at).toLocaleDateString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  })}
                </TableCell>
                {scores[i].map((team_score) => (
                  <TableCell key={team_score.team_id}>
                    {team_score.score}
                  </TableCell>
                ))}
                {groupData.permission_level >= permissions.Manager ? (
                  <TableCell className="p-0">
                    <Link
                      to={`/games/${gameID}/groups/${groupID}/trackers/${tracker.id}`}
                      className="px-2 py-4"
                      viewTransition
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </Link>
                  </TableCell>
                ) : (
                  allowRead && (
                    <TableCell className="p-0">
                      <Link
                        to={`/games/${gameID}/groups/${groupID}/trackers/${tracker.id}`}
                        className="px-2 py-4"
                        viewTransition
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Link>
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </BackBlack>
    </>
  );
}
