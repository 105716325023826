import { ActionFunctionArgs } from "react-router";
import { GroupRes } from "./game";
import { TrackerBase } from "./tracker";
import { apiFetch } from "./utils";

type ScorecardField = {
  id: string;
  name: string;
};
export type ScorecardTemplateRes = {
  id: string;
  official: boolean;
  created_at: string;
  fields: ScorecardField[];
};

type ScorecardScore = {
  field_id: string;
  team_id: string;
  score: number;
  field_name: string;
};
export type ScorecardRes = TrackerBase & {
  scores: ScorecardScore[];
};
export type ScorecardGroupRes = {
  group: GroupRes;
  scorecards: ScorecardRes[];
};

type ScorecardUpdate = {
  scores: { field_id: string; team_id: string; score: number };
};

export async function createScorecardTemplate(
  gameID: string,
  field_names: string[],
): Promise<string> {
  const response = await apiFetch(
    "POST",
    `/games/${gameID}/scorecard-template`,
    { field_names: field_names },
  );
  if (response.ok) return (await response.json()).id;
  else throw Error("Error creating scorecard template.");
}

export async function listScorecardTemplates({
  params,
}: ActionFunctionArgs): Promise<ScorecardTemplateRes[] | undefined> {
  const response = await apiFetch(
    "GET",
    `/games/${params.gameID}/scorecard-template`,
  );
  if (response.ok) return await response.json();
}

export async function createScorecard(
  groupID: string,
  templateID: string,
): Promise<Response> {
  const response = await apiFetch("POST", `/groups/${groupID}/scorecards`, {
    id: templateID,
  });
  return response;
}

export async function listScorecards({
  params,
}: ActionFunctionArgs): Promise<ScorecardRes[] | undefined> {
  const response = await apiFetch(
    "GET",
    `/groups/${params.groupID}/scorecards`,
  );
  if (response.ok) return await response.json();
}

export async function updateScorecard({
  request,
  params,
}: ActionFunctionArgs): Promise<Response> {
  const groupID = params.groupID || "";
  const trackerID = params.trackerID || "";
  const payload = (await request.json()) as ScorecardUpdate;

  const response = await apiFetch(
    "PUT",
    `/groups/${groupID}/scorecards/${trackerID}`,
    payload,
  );
  return response;
}

export async function getLastUserScorecardTemplate(
  gameID: string,
): Promise<string | undefined> {
  const response = await apiFetch(
    "GET",
    `/games/${gameID}/scorecard-template/last-used`,
  );
  if (response.ok) return (await response.json()).id;
  else return undefined;
}
