import { createContext, useContext, useState, ReactNode } from "react";

interface WizardContextType {
  nextStep: (step: string) => void;
  prevStep: () => void;
  submit: () => void;
}

const WizardContext = createContext<WizardContextType | undefined>(undefined);

export const useWizard = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error("useWizard must be used within a Wizard");
  }
  return context;
};

export const Wizard = ({
  steps,
  submit,
}: {
  steps: { [id: string]: ReactNode };
  submit?: () => void;
}) => {
  const [wizard, wizardSetter] = useState([Object.keys(steps)[0]]);

  const nextStep = (step: string) => {
    wizardSetter([...wizard, step]);
  };

  const prevStep = () => {
    let w = [...wizard];
    w.pop();
    wizardSetter(w);
  };

  const submitWrap = () => {
    if (submit) submit();
  };

  const currentStep = wizard[wizard.length - 1];

  return (
    <WizardContext.Provider value={{ nextStep, prevStep, submit: submitWrap }}>
      {steps[currentStep]}
    </WizardContext.Provider>
  );
};
