import { Link, useLoaderData } from "react-router";
import { faGear, faPlus, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { Button, CircleButton } from "@/components/ui/button";
import { GameRes, GroupRes } from "@/api/game";
import { NewCard, NewCardButton } from "@/components/ui/card";
import { AvatarTeam } from "@/components/ui/avatar";
import { permissions } from "@/permissions";
import { NavBar } from "@/components/ui/nav_bar";
import { Separator } from "@/components/ui/separator";
import { Versus } from "@/components/ui/double_letters";
import { Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { GameStore } from "@/storage";
import { BackBlack, BackWhite } from "@/components/ui/backdrop";
import { ActionIsland } from "@/components/ui/action_island";
import { MyText } from "@/components/ui/text";

export default function Home() {
  const loaderData = useLoaderData() as GameRes[];

  useEffect(() => {
    async function f() {
      for (let g of loaderData) {
        new GameStore().set(g.id, { name: g.name, type: g.type });
      }
    }
    f();
  }, []);

  function showGroups(gameID: string, groups: GroupRes[]) {
    return (
      <div className="flex flex-col gap-2">
        {groups.map((g, i) => (
          <Fragment key={g.id}>
            {i != 0 && <Separator />}
            <Link
              to={`/games/${gameID}/groups/${g.id}/trackers`}
              viewTransition
            >
              <div className="flex flex-wrap items-center gap-1">
                {g.teams.map((t, j) => (
                  <Fragment key={t.id}>
                    {j != 0 && <Versus />}
                    <AvatarTeam users={t.users} />
                  </Fragment>
                ))}
              </div>
            </Link>
          </Fragment>
        ))}
      </div>
    );
  }

  return (
    <>
      <ActionIsland>
        <CircleButton icon={faPlus} link="/games/create" />
      </ActionIsland>

      <NavBar logoWords>
        {/*<CircleButton icon={faPieChart} link="" />*/}
        <CircleButton icon={faUserGroup} link="/friends" />
        <CircleButton icon={faGear} link="/profile" />
      </NavBar>
      <BackWhite />

      <BackBlack>
        {loaderData.map((game) => (
          <div key={game.id}>
            <div className="flex items-center justify-between pb-1">
              <MyText>{game.name}</MyText>
              <MyText size="xs" alt>
                {game.type}
              </MyText>
            </div>

            <NewCard key={game.id}>
              {showGroups(game.id, game.groups)}
              {game.permission_level >= permissions.Manager && (
                <NewCardButton>
                  <Button size="sm" asChild>
                    <Link to={`/games/${game.id}/groups/create`} viewTransition>
                      New Teams
                    </Link>
                  </Button>
                </NewCardButton>
              )}
            </NewCard>
          </div>
        ))}
      </BackBlack>
    </>
  );
}
