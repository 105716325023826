import { useRegisterSW } from "virtual:pwa-register/react";
import { CircleButton } from "./components/ui/button";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { MyText } from "./components/ui/text";
import { ActionIsland } from "./components/ui/action_island";

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh, _],
    updateServiceWorker,
  } = useRegisterSW();

  const text = () => (
    <MyText size="text" alt>
      A new version is available.
      <br />
      Click to reload.
    </MyText>
  );

  return (
    <>
      {/*
      <Button
        size="sm"
        variant="outline"
        onClick={() => setNeedRefresh(false)}
      >
        Close
      </Button>
      */}
      {needRefresh && (
        <ActionIsland extra={text()} displayOverride>
          <CircleButton
            icon={faRefresh}
            onClick={() => updateServiceWorker(true)}
          />
        </ActionIsland>
      )}
    </>
  );
}

export default ReloadPrompt;
